const tailwindFontsize = require('./utils/tailwindUtils').tailwindFontsize

module.exports = {
  content: [
    './nuxt.config.js',
    './components/**/*.{js,vue,ts}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './templates/**/*.vue',
    './plugins/**/*.{js,ts}',
  ],
  theme: {
    extend: {
      colors: {
        primary: 'var(--color-primary)',
        secondary: 'var(--color-secondary)',
        tertiary: 'var(--color-tertiary)',
      },
    },
    fontSize: {
      xs: tailwindFontsize(`
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0;
      `),
      sm: tailwindFontsize(`
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0;
      `),
      base: tailwindFontsize(`
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
      `),
      lg: tailwindFontsize(`
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0;
      `),
      xl: tailwindFontsize(`
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0;
      `),
      '2xl': tailwindFontsize(`
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0;
      `),
      '3xl': tailwindFontsize(`
        font-size: 30px;
        line-height: 38px;
        letter-spacing: 0;
      `),
      '4xl': tailwindFontsize(`
        font-size: 36px;
        line-height: 45px;
        letter-spacing: -0.01em;
      `),
      '5xl': tailwindFontsize(`
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.01em;
      `),
      '6xl': tailwindFontsize(`
        font-size: 64px;
        line-height: 80px;
        letter-spacing: -0.01em;
      `),
      '7xl': tailwindFontsize(`
        font-size: 72px;
        line-height: 90px;
        letter-spacing: -0.01em;
      `),
      '8xl': tailwindFontsize(`
        font-size: 80px;
        line-height: 100px;
        letter-spacing: -0.01em;
      `),
      '9xl': tailwindFontsize(`
        font-size: 96px;
        line-height: 106px;
        letter-spacing: -0.01em;
      `),
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 700,
      black: 800,
    },
    fontFamily: {
      sans: [
        'StabilGrotesk',
        'ui-sans-serif',
        'system-ui',
        'BlinkMacSystemFont',
        'SegoeUI',
      ],
    },
    spacing: {
      px: '1px',
      0: '0px',
      1: '5px',
      2: '10px',
      3: '15px',
      4: '20px',
      5: '25px',
      6: '30px',
      7: '35px',
      8: '40px',
      9: '45px',
      10: '50px',
      11: '55px',
      12: '60px',
      13: '65px',
      14: '70px',
      15: '75px',
      16: '80px',
      18: '90px',
      19: '95px',
      20: '100px',
      24: '120px',
      28: '140px',
      30: '150px',
      32: '160px',
      34: '170px',
      36: '180px',
      38: '190px',
      40: '200px',
      44: '220px',
      48: '240px',
      52: '260px',
      56: '280px',
      60: '300px',
      64: '320px',
      68: '340px',
      72: '360px',
      80: '400px',
      96: '440px',
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/aspect-ratio'),
  ],
}
