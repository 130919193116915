export const state = () => ({
  selectedCategory: null,
  topic: '',
  description: '',
  file: null,
})

export const actions = {}

export const mutations = {
  setCategory(state, category) {
    state.selectedCategory = category
  },
  setTopic(state, topic) {
    state.topic = topic
  },
  setDescription(state, description) {
    state.description = description
  },
  setFile(state, file) {
    state.file = file
  },
  clearFile(state) {
    state.file = null
  },
  clearForm(state) {
    state.selectedCategory = null
    state.topic = ''
    state.description = ''
    state.file = null
  },
}

export const getters = {
  selectedCategory: state => state?.selectedCategory,
  topic: state => state?.topic,
  description: state => state?.description,
  file: state => state?.file,
}
