import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=e5699b3a&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadSvg: require('/workspace/components/LoadSvg.vue').default,BoardSelector: require('/workspace/components/BoardSelector.vue').default,SidebarButton: require('/workspace/components/SidebarButton.vue').default,SidebarLink: require('/workspace/components/SidebarLink.vue').default,TransitionNavigationRootMenu: require('/workspace/components/TransitionNavigationRootMenu.vue').default,LoadingSpinner: require('/workspace/components/LoadingSpinner.vue').default,SidebarParam: require('/workspace/components/SidebarParam.vue').default,AccountBar: require('/workspace/components/AccountBar.vue').default})
