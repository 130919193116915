import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/tailwind.config.js'
const { theme } = resolveConfig(tailwindConfig)

const screensAsNumbers = {}

for (const [key, value] of Object.entries(theme.screens)) {
  if (typeof value === 'string') {
    screensAsNumbers[key] = Number(value.replace('px', ''))
  }
}

export default (context, inject) => {
  const updatedTheme = {
    ...theme,
    screensAsNumbers,
  }
  inject('tailwind', updatedTheme)
}
