import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=2da612b4&"
import script from "./landing.vue?vue&type=script&lang=js&"
export * from "./landing.vue?vue&type=script&lang=js&"
import style0 from "./landing.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/workspace/components/Header.vue').default,CornerGradient: require('/workspace/components/CornerGradient.vue').default,DynamicModal: require('/workspace/components/DynamicModal.vue').default,DynamicToast: require('/workspace/components/DynamicToast.vue').default})
