//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    hideBack: { type: Boolean, default: () => false },
    hideSearch: { type: Boolean, default: () => false },
    hideAccount: { type: Boolean, default: () => false },
    hideMenu: { type: Boolean, default: () => false },
    hideHeaderTitle: { type: Boolean, default: () => false },
    forceDepartmentName: { type: Boolean, default: () => false },
  },
  computed: {
    ...mapGetters([
      'user/userInfo',
      'menuIsOpen',
      'activeParentLink',
      'activeChildLink',
      'activeFilterParams',
      'inbox/selectedMessage',
      'inbox/selectedCategory',
      'boardDocuments/currentCategory',
      'department',
      'modal/type',
      'navigation/currentParent',
    ]),
    currentParent() {
      return this?.['navigation/currentParent']
    },
    routeSplit() {
      return this.$route.fullPath.split('/')
    },
    onTopLevelRoute() {
      if (
        (this.menuIsOpen && this.activeParentLink) ||
        (this.menuIsOpen && this.activeChildLink)
      )
        return false
      const routeSplit = this.$route.fullPath.split('/')
      return routeSplit?.length == 2 ? true : false
    },
    onSecondaryRoute() {
      const routeSplit = this.$route.fullPath.split('/')
      return routeSplit?.length == 3 ? true : false
    },
    onTertiaryRoute() {
      const routeSplit = this.$route.fullPath.split('/')
      return routeSplit?.length == 4 ? true : false
    },
    notLoggedIn() {
      if (!this?.['user/userInfo']?.data) {
        // We don't have user info available, something's up.
        return true
      }
      return !this.$store.getters['auth/getToken']
    },
    homeLink() {
      if (this.departmentSlug) {
        return {
          name: 'department',
          params: {
            department: this.departmentSlug,
          },
        }
      }
      return '/'
    },
    departmentName() {
      return this.department ? this.department?.name : 'Boligfy'
    },
    departmentSlug() {
      return this.department ? this.department?.slug : ''
    },
    showRootMenu() {
      if (this.activeChildLink) return false
      if (this.activeParentLink) return false
      if (this.$route.path === '/') return true
      if (this.$route.name === 'department') return true
      return false
    },
    enableBackButton() {
      if (this.menuIsOpen && this.activeChildLink) return true
      if (this.menuIsOpen && this.activeParentLink) return true

      if (this.onTopLevelRoute) return false
      if (this.hideBack) return false

      if (this.$route.name === 'department') return false
      return true
    },
    hideBackButton() {
      if (this.$route?.path === '/login') return true
      if (this.$route?.path === '/') return true
      if (this.menuIsOpen && !this.activeParentLink && !this.activeChildLink)
        return true
      if (this.menuIsOpen) return false

      if (this.hideBack) return true
      if (this.activeChildLink) return false
      if (this.activeParentLink) return false
      if (this.$route.name === 'department') return true
      return false
    },
    headerTitle() {
      if (this.menuIsOpen) return ''
      if (this.$route?.name === 'department') return ''
      if (this.$route?.meta?.title === 'Forside') return ''
      if (this.currentParent?.name === 'department')
        return this.$route?.meta?.title

      return this.currentParent?.meta?.title
    },
    headerSubTitle() {
      // Is menu open, don't display subtitle
      if (this.menuIsOpen) return

      // Get current route title for comparison
      const currentRouteTitle = this.$route.meta.title

      // If there's a filter active, fetch it
      const inboxCategory = this?.['inbox/selectedCategory']
      const routeIsInbox = this.$route.name === 'department-inbox'
      if (routeIsInbox && inboxCategory) return inboxCategory?.name

      const boardDocumentsCategory = this?.['boardDocuments/currentCategory']
      const routeIsBoardDocuments =
        this.$route.name === 'department-board-documents'

      if (routeIsBoardDocuments && boardDocumentsCategory)
        return `${currentRouteTitle}/${boardDocumentsCategory?.name}`

      const routeIsDocumentsFromBoard =
        this.$route.name === 'department-documents-board-documents'
      if (routeIsDocumentsFromBoard && boardDocumentsCategory)
        return `${currentRouteTitle}/${boardDocumentsCategory?.name}`

      // Is current route and parent equal, we simply show parent
      if (this.headerTitle === currentRouteTitle) return
      if (this.$route?.meta?.title === 'Forside') return
      return currentRouteTitle
    },
    headerHeight() {
      return this.$refs?.header?.offsetHeight || '80'
    },
    modalType() {
      return this?.['modal/type']
    },
    modalIsOpen() {
      return this.modalType !== null
    },
  },
  methods: {
    toggleMenu() {
      this.$store.commit('setMenuIsOpen', !this.menuIsOpen)
    },
    handleBack() {
      if (this.menuIsOpen) {
        this.handleMenuBack()
        return
      }

      if (this?.['inbox/selectedMessage']) {
        this.$store.commit('inbox/setSelectedMessage', null)
        return
      }

      if (this?.['inbox/selectedCategory']) {
        this.$store.commit('inbox/setSelectedCategory', null)
      }

      if (this?.['boardDocuments/currentCategory']) {
        this.$store.commit('boardDocuments/setCurrentCategory', null)
      }

      // If there's a query param, simply navigate backwards if possible.
      if (this.$route?.query?.filter || this.$route?.query?.step) {
        const previousVueRoute = this.$nuxt?.context?.from
        if (previousVueRoute) {
          this.$router.push({ name: previousVueRoute?.name })
          return
        }
      }

      // Going back should always return user to the first route of previous level.
      // Ex: going from "department-inbox", you should land at "department" - regardless of previous route
      // Ex: going from "department-documents-boarddocuments", you should go to "department-documents"
      const parentPath = this.$route?.meta?.parent
      const parentRoute = this.$router?.options?.routes?.find(
        route => route.path === parentPath
      )
      if (parentRoute) {
        // Check if parent route should open menu on mobile
        if (parentRoute?.meta?.openMenuOnMobile) {
          this.$store.commit('setMenuIsOpen', true)
          this.$store.commit('setActiveParentLink', parentRoute)
          return
        }

        // Route back to parent
        this.$router?.push({ name: parentRoute?.name })
        return
      }
      this.$router?.go(-1)
      return
      /*
      Route based back behaviour experiment for mobile users. Disabled pending UX walkthrough

      const path = this.$route.fullPath;
      const pathWithoutLeadingSlash = path?.replace("/", "");
      const pathSplit = pathWithoutLeadingSlash?.split("/");
      const pathLength = pathSplit?.length;
      const departmentSlug = pathSplit?.[0];
      let newPath;
      if (pathSplit?.[1] === "beboerservice") {
        // Unique handling for beboerservice, as those routes all affect the third level.
        switch (pathSplit?.[2]) {
          case "beskrivelse":
            newPath = `/${departmentSlug}/beboerservice/kategori`;
            break;
          case "opsummering":
            newPath = `/${departmentSlug}/beboerservice/beskrivelse`;
            break;
          case "bekraeftelse":
            newPath = `/${departmentSlug}/beboerservice/opsummering`;
            break;
          default:
            newPath = `/${departmentSlug}`;
            break;
        }
      } else {
        switch (pathLength) {
          case 4:
            newPath = `/${departmentSlug}/${pathSplit?.[1]}/${pathSplit?.[2]}`;
            break;
          case 3:
            newPath = `/${departmentSlug}/${pathSplit?.[1]}`;
            break;
          case 2:
            newPath = `/${departmentSlug}`;
            break;
          default:
            newPath = `/${departmentSlug}`;
            break;
        }
      }

      console.log("Going to:");
      console.log(newPath);
      this.$router.push(newPath);
      return;
    */

      // Check if we've been routing within Boligfy, and if so, just go back
      const previousVueRoute = this.$nuxt?.context?.from
      if (previousVueRoute) {
        this.$router.go(-1)
        return
      }

      // Fallback to window.history.back()
      window.history.back()
      return

      if (this.$route.query.filter) {
        this.$router.push({ name: this.$route.name })
        return
      }

      if (this.activeParentLink)
        this.$router.push({ name: this.activeParentLink.name })
      if (this.activeChildLink)
        this.$store.commit('setActiveChildLink', undefined)

      if (this.$route.name === this.activeParentLink?.name) {
        this.$router.push({ name: 'department' })
      }

      if (!this.activeParentLink && !this.activeChildLink)
        this.$router.push({ name: 'department' })
    },
    handleMenuBack() {
      if (this.activeFilterParams) {
        this.$store.commit('setActiveFilterParams', undefined)
        if (!this.activeChildLink)
          this.$store.commit('setActiveParentLink', undefined)
        return
      }

      if (this.activeChildLink) {
        this.$store.commit('setActiveChildLink', undefined)
        this.$store.commit('setActiveParentLink', undefined)
        return
      }

      if (this.activeParentLink) {
        this.$store.commit('setActiveParentLink', undefined)
        return
      }
    },
    getFilterName(filterId) {
      if (!this.activeFilterParams) return ''
      const filter = this.activeFilterParams.find(
        filter => filter.id === parseInt(filterId)
      )
      if (!filter) return ''
      return filter.name
    },
    updateHeaderHeight() {
      const root = document.documentElement
      root?.style?.setProperty('--header-height', this.headerHeight + 'px')
    },
  },
  mounted() {
    this.updateHeaderHeight()
  },
  watch: {
    headerHeight() {
      this.updateHeaderHeight()
    },
  },
}
