import Vue from 'vue'

const mixin = {
  methods: {
    $t(translation) {
      const translations = this.$store.getters['translations/getTranslations']

      const flattenObj = object => {
        let result = {}
        // Loop over all keys in object
        for (const index in object) {
          if (
            typeof object[index] === 'object' &&
            !Array.isArray(object[index])
          ) {
            // Recursively loop over keys within key
            const nestedKey = flattenObj(object[index])
            for (const nestedIndex in nestedKey) {
              // Store temp in result
              result[index + '.' + nestedIndex] = nestedKey[nestedIndex]
            }
          } else {
            result[index] = object[index]
          }
        }
        return result
      }

      const translationsFlat = flattenObj(translations)

      return (
        translationsFlat?.[translation] ?? 'translation missing: ' + translation
      )
    },
  },
}

Vue.mixin(mixin)
