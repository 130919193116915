//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      links: [],
      animateLeft: false,
    }
  },
  computed: {
    ...mapGetters([
      'user/userInfo',
      'department',
      'departmentExtData',
      'menuIsOpen',
      'sublink',
      'activeParentLink',
      'activeChildLink',
      'activeFilterParams',
      'fetchingActiveFilterParams',
      'activeChildFilterParams',
      'activeFilterSiblings',
      'fetchingChildFilterParams',
    ]),
    token() {
      return this.$store.getters['auth/getToken']
    },
    tenantIsBoardMember() {
      const currentTenant = this.$store.getters['user/currentTenant']
      const isPartOfBoard = currentTenant?.roller?.length > 0
      return isPartOfBoard
    },
    showRootMenu() {
      if (this.activeParentLink) return false
      if (this.activeFilterParams) return false
      return true
    },
    showBottomComponent() {
      if (!this.bottomComponent) return false
      // We have a bottom component on current route
      const bottomComponentRoute = this.$route

      // Check if on board page, and if tenant is part of board
      if (
        (this.$route.name === 'department-documents-board-documents' &&
          !this.tenantIsBoardMember) ||
        (this.$route.name === 'department-board-documents' &&
          !this.tenantIsBoardMember)
      )
        return false

      // Check if we're currently showing this route in nav, and if not, hide bottom component
      if (this.activeChildLink?.name == bottomComponentRoute?.name)
        return true
      if (this.activeParentLink?.name == bottomComponentRoute?.name)
        return true
      return false
    },
    showBoardSelector() {
      // Hide selector if not part of a board
      if (!this.tenantIsBoardMember) return false
      // Hide selector if we're not on board page
      if (this.$route?.name !== 'department-board-documents') return false
      // Hide selector while fetching board filterParams
      if (!this.activeFilterParams) return false

      return true
    },
    bottomComponent() {
      return this.$route.meta?.bottomComponent
    },
    hasChildFilters() {
      return (
        this.activeChildFilterParams?.length > 0 ||
        this.fetchingChildFilterParams !== null
      )
    },
    childFilterHeader() {
      return this.activeChildFilterParams?.[0]?.parentName
    },
    hasFilterSiblings() {
      return this.$route?.meta?.hasFilterSiblings
      return this.activeFilterSiblings !== null
    },
    animateRight() {
      return !this.animateLeft
    },
    departmentHasLaundry() {
      return this.departmentExtData?.harVaskeri
    },
    tenantHasMaintenanceAccount() {
      return this?.['user/userInfo']?.data?.tenant?.forbrugsIndex
        ?.harVedligeholdskonto
    },
    tenantHasConsumption() {
      return this?.['user/userInfo']?.data?.tenant?.forbrugsIndex
        ?.harForbrugsregnskab
    },
    tenantHasLaundryConsumption() {
      return this?.['user/userInfo']?.data?.tenant?.forbrugsIndex
        ?.harVaskeforbrug
    },
    filteredChildLinks() {
      return this.filterLinks(this.activeParentLink?.meta?.children)
    },
  },
  methods: {
    handleBack() {
      // When a user presses back from sidebar, we should jump upwards to a parent or grandparent link.

      const parentPath = this.$route?.meta?.parent
      const parentRoute = this.$router?.options?.routes?.find(
        route => route?.path === parentPath
      )

      // Filter params should jump back to parent, but siblings should go straight to grandparent
      if (!this.activeFilterParams) {
        const grandParentPath = parentRoute?.meta?.parent
        const grandParentRoute = this.$router?.options?.routes?.find(
          route => route?.path === grandParentPath
        )
        if (!grandParentPath) {
          this.$router.go(-1)
          this.resetParams()
          this.resetChild()
          return
        }
        this.$router.push({ name: grandParentRoute?.name })
        this.resetParams()
        this.resetChild()
        return
      }
      this.resetParams()
      this.resetChild()
      this.$router.push({ name: parentRoute?.name })
      return
    },
    resetParams() {
      this.$store.commit('setActiveFilterParams', undefined)
    },
    resetChild() {
      this.$store.commit('setActiveChildLink', undefined)
    },
    getMenuTitle() {
      if (
        !this.activeParentLink &&
        !this.activeChildLink &&
        !this.activeFilterParams
      ) {
        return 'Hovedmenu'
      }
      if (
        this.activeParentLink &&
        !this.activeChildLink &&
        !this.activeFilterParams
      ) {
        return this.activeParentLink.meta.title
      }
      if (
        this.activeParentLink &&
        this.activeChildLink &&
        !this.activeFilterParams
      ) {
        return this.activeParentLink.meta.title
      }
      if (
        this.activeParentLink &&
        this.activeChildLink &&
        this.activeFilterParams &&
        !this.hasFilterSiblings
      ) {
        return `${this.activeParentLink.meta.title} / ${this.activeChildLink.meta.title}`
      }
      if (
        this.activeParentLink &&
        !this.activeChildLink &&
        this.activeFilterParams
      ) {
        return `${this.activeParentLink.meta.title}`
      }
      return this.$route.meta.title
    },
    restoreMenuState(route) {
      // Clear active links when routing
      this.$store.commit('setActiveParentLink', undefined)
      this.$store.commit('setActiveChildLink', undefined)

      if (route.name === 'department') {
        this.resetMenu()
        return
      }
      // Check if the link has children, if so, mark it as the activeParentLink
      if (route.meta.children) {
        this.$store.commit('setActiveParentLink', route)
        return
      }
      // If we're in a child link, find parentLink within this.links and set as active
      const path = route.path.split('/')
      if (path.length > 3) {
        const parentLinkPath = `/:department/${path[2]}`
        const parentLink = this.links.find(
          link => link.path === parentLinkPath
        )
        if (!parentLink) return
        if (parentLink.meta.children) {
          this.$store.commit('setActiveParentLink', parentLink)
        }
        if (!this.activeParentLink) return
        // Find a possible child link within activeParentLink's children
        const childLinkPath = `${parentLinkPath}/${path[3]}`
        const childLink = this.activeParentLink.meta?.children?.find(
          link => link.path === childLinkPath
        )
        if (!childLink) return
        this.$store.commit('setActiveChildLink', childLink)
      }
      // If we don't have child link, but we have params, set those as active.
      if (!route.meta.children && route.meta.params) {
        const parentLinkPath = `/:department/${path[2]}`
        const parentLink = this.links.find(
          link => link.path === parentLinkPath
        )
        if (!parentLink) return
        this.$store.commit('setActiveParentLink', parentLink)
        return
      }
      // If we don't have children or params, go back to root
      if (path.length < 4 && !route.meta.children && !route.meta.params) {
        this.$store.commit('setActiveParentLink', undefined)
      }
    },
    resetMenu() {
      this.$store.commit('setMenuIsOpen', false)
      this.$store.commit('setActiveParentLink', undefined)
      this.$store.commit('setActiveChildLink', undefined)
      this.$store.commit('setActiveFilterParams', undefined)
    },
    toggleChildren(parent) {
      // If we click on a top-level link that *has* children, this is run to transform the menu.
      this.$store.commit('setActiveParentLink', parent)
      this.$store.commit('setMenuIsOpen', true)
    },
    toggleFilterParams(parent) {
      // A parent in this case can either be a top-level, or child-level link.
      this.$router.push({ name: parent.name })
      this.$store.commit('setMenuIsOpen', false)
    },
    filterLinks(links) {
      // Loop over all links and make sure to remove not applicable links, eg. laundry consumption if department doesn't have a laundry service
      if (!links) return
      const mappedLinks = links.map(link => {
        if (
          link?.name === 'department-my-unit-laundryconsumption' &&
          !this.tenantHasLaundryConsumption
        )
          return null
        if (
          link?.name === 'department-my-unit-laundryconsumption' &&
          !this.departmentHasLaundry
        )
          return null
        if (
          link?.name === 'department-my-unit-maintenanceaccount' &&
          !this.tenantHasMaintenanceAccount
        )
          return null
        if (
          link?.name === 'department-my-unit-consumption' &&
          !this.tenantHasConsumption
        )
          return null
        return link
      })
      const filteredLinks = mappedLinks?.filter(link => link !== null)
      return filteredLinks
    },
  },
  mounted() {
    const departmentRoute = this.$router.options.routes.find(
      route => route.name === 'department'
    )
    const links = departmentRoute.meta.children.map(link => {
      return this.$router.options.routes.find(
        route => route.name === link.name
      )
    })

    const filteredLinks = this.filterLinks(links)
    this.links = filteredLinks
    this.restoreMenuState(this.$route)
  },
  watch: {
    $route(to, from) {
      this.$store.commit('setMenuIsOpen', false)
      this.animateLeft = true
      this.restoreMenuState(this.$route)
      if (!to.name.includes('department-tenant-service')) {
        this.$store.commit('service/clearForm')
      }

      // Let animation complete before resetting animateLeft
      setTimeout(() => {
        this.animateLeft = false
      }, 500)
    },
    async menuIsOpen(menuIsOpen) {
      const body = document.querySelector('body')
      if (menuIsOpen == true) {
        this.animateLeft = false
        body.style.overflow = 'hidden'
        await this.$nextTick()
        this.$refs?.rootMenu?.scrollTo({ top: 0 })
        this.$refs?.childMenu?.scrollTo({ top: 0 })
        return
      }
      body.style.overflow = 'unset'
      this.restoreMenuState(this.$route)
    },
  },
}
