//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    image: String,
    svg: { type: String, default: 'not-found' },
    title: String,
    subtitle: String,
    ctaText: String,
    ctaLink: String,
    externalLink: String,
    noImage: { type: Boolean, default: false },
  },
}
