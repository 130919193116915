function cssStringToObject(string) {
  return string
    .replace(/\n/gi, '')
    .split(';')
    .filter(Boolean)
    .map(cssLine => {
      const [k, value] = cssLine.split(':')
      const key = k.replace(/\s*/gi, '')
      return { key, value }
    })
}
function getKeyFromCollection(collection, key) {
  return collection.find(item => item.key === key)
}
function convertToUnit(
  value,
  { divideBy = 16, prefix = '', append = '', unit = 'rem' } = {}
) {
  return `${value / divideBy}${unit}`
}

function tailwindFontsize(cssString) {
  const cssObj = cssStringToObject(cssString)
  const { value: fontSizeString } = getKeyFromCollection(cssObj, 'font-size')
  const { value: letterSpacing } = getKeyFromCollection(
    cssObj,
    'letter-spacing'
  )
  const { value: lineHeightString } = getKeyFromCollection(
    cssObj,
    'line-height'
  )
  const fontSize = parseFloat(fontSizeString)
  const lineHeight = parseFloat(lineHeightString) / fontSize
  return [
    convertToUnit(fontSize),
    {
      letterSpacing,
      lineHeight,
    },
  ]
}

module.exports = {
  tailwindFontsize,
}
