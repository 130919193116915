//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: { results: Array, icon: String, title: String, type: String },
  data() {
    return {
      downloadingNoteId: null,
    }
  },
  computed: {
    hasResults() {
      return this.results?.length > 0
    },
    departmentSlug() {
      const department = this.$store.getters['department']
      return department?.slug || ''
    },
  },
  methods: {
    getTitle(result) {
      if (this.type === 'external_document') {
        let title = result?.noteTypeTekst
        if (result.bemærkning && title !== result.bemærkning)
          title = `${title} (${result.bemærkning})`
        return title
      }
      return result?.header || result?.name || result?.title
    },
    getLink(result) {
      const slug = result?.slug || result?.id
      if (this.type === 'sustainable-action')
        return `/${this.departmentSlug}/bestyrelse/baeredygtige-tiltag/${slug}`
      if (this.type === 'article')
        return `/${this.departmentSlug}/hjaelpeartikler/${slug}`
      if (this.type === 'document') return result.azure_file_name
      return `/${this.departmentSlug}${result?.path}`
    },
    async downloadExternalDocument(result) {
      this.downloadingNoteId = result?.noteId
      const externalDocumentReference = result?.noteId
      let title = result?.noteTypeTekst
      if (result.bemærkning && title !== result.bemærkning)
        title = `${title} (${result.bemærkning})`
      const department =
        this.$store.getters['user/userinfo']?.data?.department ||
        this.$store.getters['department']
      const externalDepartmentId = department.ext_department_id
      await this.$externalDocument(
        title,
        externalDepartmentId,
        externalDocumentReference
      )
      this.downloadingNoteId = null
    },
  },
}
