export const state = () => ({
  department: null,
  departmentExtData: null,
  boligfyVersion: '',
  theme: null,
  menuIsOpen: false,
  sublink: null,
  editDocument: { fileName: 'test' },
  translateToEnglish: false,
  activeParentLink: null,
  activeChildLink: null,
  activeFilterParams: null,
  fetchingActiveFilterParams: false,
  activeChildFilterParams: null,
  fetchingChildFilterParams: null,
  activeFilterSiblings: null,
  showTechnicalIssuesOverlay: false,
})

export const actions = {
  // On fresh reloads we immediately check if there's active maintenance and update the store + redirect to login
  async nuxtServerInit({ commit }, { redirect }) {
    const activeMaintenance = await this.$api.maintenance()
    if (activeMaintenance) {
      commit('setShowTechnicalIssuesOverlay', true)
      redirect('/login')
    }
  },
  async fetchDepartment({ commit }, slug) {
    try {
      if (!slug) return
      const department = await this.$api.departmentBySlug(slug)
      commit('setDepartment', department?.data)
      commit('setTheme', department?.data?.theme)

      const extData = await this.$api.departmentExtDataById(
        department?.data?.ext_department_id
      )
      commit('setDepartmentExtData', extData)
    } catch (error) {
      console.log('Error when fetching department:')
      console.log(error)
      this.$bugsnag.notify(new Error(error))
    }
  },
}

export const mutations = {
  setDepartment(state, department) {
    state.department = department
  },
  setDepartmentExtData(state, departmentExtData) {
    state.departmentExtData = departmentExtData
  },
  setTheme(state, theme) {
    state.theme = theme
  },
  setMenuIsOpen(state, menuIsOpen) {
    state.menuIsOpen = menuIsOpen
  },
  setSublink(state, sublink) {
    state.sublink = sublink
  },
  setEditDocument(state, document) {
    state.editDocument = document
  },
  setTranslateToEnglish(state, translateToEnglish) {
    state.translateToEnglish = translateToEnglish
  },
  setActiveParentLink(state, parentLink) {
    state.activeParentLink = parentLink
  },
  setActiveChildLink(state, childLink) {
    state.activeChildLink = childLink
  },
  setActiveFilterParams(state, filterParams) {
    state.activeFilterParams = filterParams
  },
  setFetchingActiveFilterParams(state, status) {
    state.fetchingActiveFilterParams = status
  },
  setActiveChildFilterParams(state, filterParams) {
    state.activeChildFilterParams = filterParams
  },
  setFetchingChildFilterParams(state, status) {
    state.fetchingChildFilterParams = status
  },
  setActiveFilterSiblings(state, siblings) {
    state.activeFilterSiblings = siblings
  },
  setBoligfyVersion(state, version) {
    state.boligfyVersion = version
  },
  setShowTechnicalIssuesOverlay(state, show) {
    state.showTechnicalIssuesOverlay = show
  },
}

export const getters = {
  sublink: state => state?.sublink,
  activeParentLink: state => state?.activeParentLink,
  activeChildLink: state => state?.activeChildLink,
  activeFilterParams: state => state?.activeFilterParams,
  fetchingActiveFilterParams: state => state?.fetchingActiveFilterParams,
  activeChildFilterParams: state => state?.activeChildFilterParams,
  fetchingChildFilterParams: state => state?.fetchingChildFilterParams,
  activeFilterSiblings: state => state?.activeFilterSiblings,
  department: state => state?.department,
  departmentExtData: state => state?.departmentExtData,
  menuIsOpen: state => state?.menuIsOpen,
  editDocument: state => state?.editDocument,
  translateToEnglish: state => state?.translateToEnglish,
  theme: state => state?.theme,
  boligfyVersion: state => state.boligfyVersion,
  showTechnicalIssuesOverlay: state => state.showTechnicalIssuesOverlay,
}
