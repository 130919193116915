export const state = () => ({
  userInfo: null,
  tenantId: null,
  currentTenant: null,
})

export const actions = {
  async fetchUser({ commit }) {
    try {
      const tenant = await this.$api.tenant()
      const userData = tenant ?? null
      commit('setUser', userData)

      if (!userData) {
        console.error("Couldn't fetch tenant")
        // Something went wrong when fetching tenant. Remove any tokens we might have had.

        this.$cookies.remove('boligfy-auth')
        this.$cookies.remove('mitID-auth')
        commit('auth/setToken', null, { root: true })
        commit('setUser', null)
      }

      if (tenant) {
        // Grab personId and set as tenantId
        const personId = tenant?.data?.ub4PersonId
        const isExternalPerson = tenant?.data?.external_person

        if (!personId) {
          console.error('MISSING PERSON_ID ON TENANT')
        }

        commit('setTenantId', personId)

        // Based on tenantID, find current tenant in lejerPersoner
        const currentTenant = isExternalPerson
          ? tenant?.data?.external_person
          : tenant.data?.tenant?.lejerPersoner?.find(
              person => person.personId === personId
            )

        if (!currentTenant) {
          console.error("COULDN'T FIND PERSONID IN LEJERPERSONER")
        }

        commit('setCurrentTenant', currentTenant)
      }
    } catch (error) {
      console.log("Couldn't fetch user:")
      console.error(error)
      return {}
    }
  },
  async logOut({ commit }) {
    this.$cookies.remove('boligfy-auth')
    this.$cookies.remove('mitID-auth')
    commit('setUser', null)
  },
}

export const mutations = {
  setUser(state, userInfo) {
    state.userInfo = userInfo

    if (userInfo === null) {
      state.tenantId = null
      state.currentTenant = null
    }
  },

  setTenantId(state, tenantId) {
    state.tenantId = tenantId
  },

  setCurrentTenant(state, currentTenant) {
    state.currentTenant = currentTenant
  },
}

export const getters = {
  userInfo: state => state?.userInfo,
  tenantId: state => state?.tenantId,
  currentTenant: state => state?.currentTenant,
}
