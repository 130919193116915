//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    query: String,
    documentResults: Array,
    handbookResults: Array,
    departmentDocumentResults: Array,
    tenantDocumentResults: Array,
    pageResults: Array,
    sustainableActionsResults: Array,
    isLoading: Boolean,
  },
  computed: {
    noResults() {
      return (
        !this.departmentDocumentResults?.length > 0 &&
        !this.tenantDocumentResults?.length > 0 &&
        !this.documentResults?.length > 0 &&
        !this.handbookResults?.length > 0 &&
        !this.pageResults?.length > 0 &&
        !this.sustainableActionsResults?.length > 0
      )
    },
  },
}
