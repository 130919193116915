export const state = () => ({
  translations: null,
})

export const mutations = {
  setTranslations(state, translations) {
    state.translations = translations
  },
}

export const getters = {
  getTranslations: state => state.translations,
}
