//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import da from '../static/locales/da.json'
import { mapGetters } from 'vuex'

export default {
  name: 'default',
  computed: {
    ...mapGetters({
      modalType: 'modal/type',
      drawerType: 'drawer/type',
      toastType: 'toast/type',
      userInfo: 'user/userInfo',
      menuIsOpen: 'menuIsOpen',
    }),
    isLoggedIn() {
      return this.$store.getters['auth/getToken'] != null
    },
    isScreenMd() {
      return this.$store.getters['screen/md']
    },
    modalIsOpen() {
      return this.modalType !== null
    },
    departmentName() {
      return this.userInfo?.data?.department?.name
    },
    theme() {
      return (
        this.userInfo?.data?.department?.theme || this.$store.getters['theme']
      )
    },
    routeName() {
      if (this.$route.name === 'department') return null
      return this.$route?.meta?.title
    },
    grayBg() {
      if (this.$route.name === 'frontpage') return true
      if (this.$route.name === 'department') return true
      if (this.$route.name === 'department-tenant-service') return true
      if (this.$route.name === 'department-administrative-help') return true
      return false
    },
    pageTitle() {
      let pageTitle = 'Boligfy'
      if (this.departmentName) pageTitle = `${this.departmentName} | Boligfy`
      if (this.departmentName && this.routeName)
        pageTitle = `${this.routeName} | ${this.departmentName} | Boligfy`
      return pageTitle
    },
    pageDescription() {
      if (!this.$route?.meta?.description)
        return this.$t('meta.page-descriptions.generic')
      return this.$route?.meta?.description
    },
    primaryColor() {
      return this.theme?.color_3 || '#A5ED7B'
    },
    secondaryColor() {
      return this.theme?.color_2 || '#2A7865'
    },
    tertiaryColor() {
      return this.theme?.color_1 || '#003E2F'
    },
  },
  created() {
    // TODO implement proper translations from backend
    const translations = da
    this.$store.commit('translations/setTranslations', translations)
  },
  mounted() {
    // If we're routing somewhere not inbox, make sure we get inbox count.
    if (this.route?.name !== 'department-inbox' && this.isLoggedIn) {
      this.$store.dispatch('inbox/getCount')
    }

    // Style google translate widget
    const translateContainer = document.querySelector(
      '.google_translate_container'
    )
    translateContainer.classList.remove('google_translate_container--landing')
    translateContainer.classList.add('google_translate_container--default')
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageDescription,
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          hid: 'cookiebot-script',
          id: 'Cookiebot',
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': 'c3ae4fb4-3363-43c8-8322-b2dde90de6ba',
          'data-blockingmode': 'auto',
        },
      ],
    }
  },
  watch: {
    modalIsOpen(open) {
      return
      if (window.innerWidth > 768) return
      const app = this.$refs.app
      if (open) {
        this.$gsap.to(app, {
          scale: 0.95,
          y: 7,
          duration: 0.2,
        })
      } else {
        this.$gsap.to(app, {
          scale: 1,
          y: 0,
          duration: 0.2,
          clearProps: 'all',
        })
      }
    },
    menuIsOpen(isOpen) {
      if (isOpen) {
        document.querySelector('body').classList.add('show-google-translate')
      } else {
        document
          .querySelector('body')
          .classList.remove('show-google-translate')
      }
    },
  },
}
