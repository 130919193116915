const getBoligfyVersion = department => {
  const hasBoardModule = department?.board_module
  const hasTenantModule = department?.tenant_module

  // depending on active modules, set boligfyVersion to either 'board-only', 'tenant-only' or 'full'.
  const isBoardOnly = !hasTenantModule && hasBoardModule
  const isTenantOnly = hasTenantModule && !hasBoardModule

  let boligfyVersion = 'full'
  if (isBoardOnly) boligfyVersion = 'board-only'
  if (isTenantOnly) boligfyVersion = 'tenant-only'
  return boligfyVersion
}

export default async function ({
  from,
  redirect,
  app,
  route,
  store,
  params,
  $api,
}) {
  const isLoginPage = route.path === '/login'

  // We fetch the maintenance status in a non-blocking way, and if the maintenance is active, we redirect to login.
  $api.maintenance().then(activeMaintenance => {
    if (activeMaintenance) {
      store.commit('setShowTechnicalIssuesOverlay', true)
      if (!isLoginPage) {
        redirect('/login')
      }
    }
  })
  // Authentication for login protected pages
  const routeIsPublic = route?.meta?.[0]?.public

  // Prep boligfyVersion for later
  let boligfyVersion = store.getters['boligfyVersion'] || ''

  // Check if we have departmentInfo already to determine whether or not to fetch it.
  const hasDepartmentInfo = store.getters['department']

  // Grab auth token
  const token =
    store.getters['auth/getToken'] || app.$cookies.get('boligfy-auth')

  // List of routes not specified in routes.js
  const allowedRoutes = ['/', '/callback', '/login', '/reset-password']

  if (!token && !routeIsPublic && !allowedRoutes?.includes(route.path)) {
    if (!from) {
      redirect('/')
    }
    // No token and trying to access non-public route, redirect to login
    store.commit('modal/setModal', {
      type: 'NotLoggedInModal',
      data: {
        icon: 'log-in',
        title: 'Log ind',
      },
      disableOverlayClick: true,
    })
    redirect(from)
  }

  if (token) {
    // Save token in store
    store.commit('auth/setToken', token)

    // Refresh token cookie
    store.commit('auth/setTokenCookie', token)

    // Check if we already have user info, and if not, fetch it.
    let userInfo = store.getters['user/userInfo']
    if (!userInfo) {
      await store.dispatch('user/fetchUser')
      userInfo = store.getters['user/userInfo']
    }

    // Set department
    const userIsExternal =
      userInfo?.data?.external_person !== undefined ?? false

    const userDepartment = userIsExternal
      ? {
          ...userInfo?.data?.department,
          board_module: true,
          tenant_module: false,
        }
      : userInfo?.data?.department

    store.commit('setDepartment', userDepartment)

    // Update Boligfy version based on department info
    let boligfyVersion = getBoligfyVersion(userDepartment)
    store.commit('setBoligfyVersion', boligfyVersion)

    // Check given route's boligfyVersion & compare w/ departments
    if (route.meta[0].boligfyVersion) {
      if (boligfyVersion === 'full') return // If department has full boligfy, do nothing here.

      const routeBoligfyVersion = route.meta[0].boligfyVersion

      // Check if department & route share the same BoligfyVersion, if not, we should prevent routing & inform user.
      if (boligfyVersion !== routeBoligfyVersion) {
        if (process.server) {
          redirect(from || '/')
        } else {
          store.commit('modal/setModal', {
            type: 'AccessDeniedModal',
            data: {
              icon: 'Close',
              title: `Ingen adgang til ${route.meta[0].title}`,
            },
            disableOverlayClick: true,
          })
          redirect(from || '/')
        }
      }
    }

    // Check if we're accessing "/", "login" or another department, and if so, redirect to correct department
    const departmentSlug = userDepartment?.slug

    // Update Boligfy version based on department info
    boligfyVersion = getBoligfyVersion(userDepartment)
    store.commit('setBoligfyVersion', boligfyVersion)

    if (
      route.path == '/' ||
      isLoginPage ||
      route.params.department !== departmentSlug
    ) {
      redirect(departmentSlug)
      return
    }

    return
  }

  // If we have a department in params, make sure to fetch it
  const slug = params.department
  // Check if we have a department in store, and if not, fetch it.
  if (!hasDepartmentInfo && slug) {
    await store.dispatch('fetchDepartment', slug)

    // Make sure we can find a department from the slug, and if not, redirect to /
    const departmentInfo = store.getters['department']
    if (!departmentInfo) redirect('/')
  }

  const department = store.getters['department']
  boligfyVersion = getBoligfyVersion(department)
  store.commit('setBoligfyVersion', boligfyVersion)
}
