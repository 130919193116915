//
//
//
//
//
//
//
//
//
//
//
//
//

import uniqBy from 'lodash/uniqBy'
export default {
  data() {
    return {
      selectedOwnerExtId: '',
      boardOptions: [],
    }
  },
  computed: {
    tenantInfo() {
      return this.$store.getters['user/userInfo']
    },
    currentTenant() {
      return this.$store.getters['user/currentTenant']
    },
    boards() {
      return uniqBy(this.currentTenant?.roller, 'navn')
    },
    tenantHasMultipleBoards() {
      return this.boards?.length > 1
    },
    boardsMapped() {
      return this.boards?.map(board => {
        return {
          name: board?.navn,
          ext_id: `${board?.selskabsNr}-${board?.afdelingsNr}`,
          department_id: board?.afdelingsNr,
          organization_id: board?.selskabsNr,
        }
      })
    },
  },
  methods: {
    async fetchBoardOptions() {
      // Query backend with mapped boards from tenant info, so they can filter out boards that aren't part of boligfy
      let filteredBoards = this.boardsMapped

      try {
        const data = await this.$api.getBoardOptions(this.boardsMapped)
        filteredBoards = data?.data
      } catch (e) {
        console.log(e)
      }

      const currentDepartmentId =
        this.tenantInfo?.data?.department?.ext_department_id
      const currentDepartmentBoard = filteredBoards?.filter(
        board => board.ext_id === currentDepartmentId
      )

      const boardsFilteredAndSorted = uniqBy(
        [currentDepartmentBoard, filteredBoards].flat(),
        'ext_id'
      )

      const filteredBoardOptions = boardsFilteredAndSorted?.map(board => {
        return {
          value: board.ext_id,
          content: board.name,
        }
      })

      this.boardOptions = filteredBoardOptions
    },
  },
  async mounted() {
    await this.fetchBoardOptions()

    const ownerExtId =
      this.$store.getters['boardDocuments/ownerExtId'] ??
      this.boardOptions?.[0]?.value
    this.selectedOwnerExtId = ownerExtId
  },
  watch: {
    selectedOwnerExtId(id) {
      // Tell board-documents to use new id in subsequent fetches & refresh list!
      this.$nuxt.$emit('onBoardChange', id)
      this.fetchBoardOptions()
    },
  },
}
