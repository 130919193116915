//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: "landing",
};
