var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation lg:min-h-unset lg:min-w-[300px] lg:bg-white",class:{ 'min-h-screen': _vm.$store.state.menuIsOpen }},[(_vm.links && _vm.links.length)?_c('div',{staticClass:"lg:h-viewport fixed top-0 z-10 flex h-full w-screen flex-col overflow-y-auto border-r border-gray-300 bg-white px-0 pt-16 transition-all duration-300 ease-in-out lg:sticky lg:top-[var(--header-height)] lg:w-60 lg:min-w-[300px] lg:flex-shrink-0 lg:translate-x-0 lg:overflow-y-hidden lg:bg-white lg:pt-10 lg:transition-none",class:{
      'translate-x-full opacity-0 lg:opacity-100':
        !_vm.$store.state.menuIsOpen && _vm.animateRight,
      '-translate-x-full opacity-0 lg:opacity-100':
        !_vm.$store.state.menuIsOpen && _vm.animateLeft,
    }},[_c('Transition',{attrs:{"name":"fadeIn","appear":""}},[_c('button',{staticClass:"group absolute top-6 left-6 hidden lg:block",class:{ 'pointer-events-none opacity-0': _vm.showRootMenu },on:{"click":_vm.handleBack}},[_c('LoadSvg',{staticClass:"w-3 transition group-hover:-translate-x-1",attrs:{"icon":"Arrow left"}})],1)]),_vm._v(" "),(!_vm.hasFilterSiblings)?_c('div',{staticClass:"mb-3 flex flex-col gap-y-2 px-4 text-xs font-bold uppercase text-[#1a1b22] lg:mt-4 lg:px-6"},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.getMenuTitle()))])]):_vm._e(),_vm._v(" "),(_vm.showBoardSelector)?_c('BoardSelector'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full transition-[opacity,transform] duration-300 ease-in-out",class:[
        _vm.showRootMenu
          ? 'flex-1 translate-x-0 pb-4 opacity-100 delay-75'
          : 'pointer-events-none -translate-x-10 opacity-0' ]},[_c('TransitionNavigationRootMenu',[_c('ul',{ref:"rootMenu",staticClass:"hide-scrollbar absolute top-0 h-full w-full space-y-8 overflow-y-auto overflow-x-visible border-t border-gray-300 px-4 pt-3 lg:border-0 lg:px-6 lg:pb-6"},_vm._l((_vm.links),function(link){return _c('li',{key:link.name,staticClass:"transform-gpu",attrs:{"data-animation":""}},[(link.meta.children)?_c('SidebarButton',{staticClass:"lg:hidden",attrs:{"click":function () { return _vm.toggleChildren(link); },"link":link}}):_vm._e(),_vm._v(" "),_c('SidebarLink',{class:{ 'hidden lg:flex': link.meta.children },attrs:{"link":link}})],1)}),0)])],1),_vm._v(" "),_c('div',{staticClass:"w-full transition-[opacity,transform] duration-300 ease-in-out",class:[
        _vm.activeParentLink && !_vm.activeFilterParams
          ? 'flex-1 translate-x-0 pb-4 opacity-100 delay-75'
          : 'pointer-events-none translate-x-10 opacity-0' ]},[(_vm.activeParentLink && !_vm.activeFilterParams)?_c('div',{staticClass:"hide-scrollbar relative h-full space-y-8 overflow-y-auto border-t border-gray-300 px-4 pt-3 lg:border-0 lg:px-6 lg:pb-6"},[_c('ul',{ref:"childMenu",staticClass:"space-y-8"},_vm._l((_vm.filteredChildLinks),function(childLink){return _c('li',{key:childLink.name},[(childLink.meta.params)?_c('SidebarButton',{attrs:{"click":function () { return _vm.toggleFilterParams(childLink); },"link":childLink}}):(!childLink.meta.childParams)?_c('SidebarLink',{attrs:{"link":childLink}}):_vm._e()],1)}),0),_vm._v(" "),(_vm.hasChildFilters)?_c('ul',{staticClass:"space-y-6"},[_c('p',{staticClass:"text-xs font-bold uppercase text-[#1a1b22]"},[_vm._v("\n            "+_vm._s(_vm.childFilterHeader)+"\n          ")]),_vm._v(" "),(_vm.fetchingChildFilterParams)?_c('LoadingSpinner',{staticClass:"mx-auto block"}):_vm._l((_vm.activeChildFilterParams),function(filter){return _c('li',{key:filter.id},[_c('SidebarLink',{attrs:{"link":filter}})],1)})],2):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"w-full overflow-x-clip transition-[opacity,transform] duration-300 ease-in-out lg:overflow-x-auto",class:[
        _vm.activeFilterParams
          ? 'flex-1 translate-x-0 pb-4 opacity-100 delay-75'
          : 'pointer-events-none translate-x-10 opacity-0' ]},[(_vm.hasFilterSiblings)?_c('ul',{staticClass:"mt-3 mb-6 flex h-max min-h-[50px] flex-col space-y-6 px-4 text-xs lg:px-6"},[_c('span',{staticClass:"text-xs font-bold uppercase text-[#1a1b22]"},[_vm._v("\n          Dokumenter\n        ")]),_vm._v(" "),_vm._l((_vm.activeFilterSiblings),function(sibling){return _c('SidebarLink',{key:sibling.name,attrs:{"link":sibling}})})],2):_vm._e(),_vm._v(" "),(_vm.activeFilterParams)?_c('ul',{staticClass:"mt-3 flex h-max flex-col space-y-6 px-4 text-xs lg:px-6"},[(_vm.hasFilterSiblings)?_c('span',{staticClass:"text-xs font-bold uppercase text-[#1a1b22]"},[_vm._v("\n          "+_vm._s(_vm.getMenuTitle())+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.activeFilterParams),function(filterParam){return _c('li',{key:filterParam.value},[_c('SidebarParam',{attrs:{"param":filterParam}})],1)}),_vm._v(" "),(_vm.fetchingActiveFilterParams)?_c('LoadingSpinner',{staticClass:"mx-auto block"}):_vm._e(),_vm._v(" "),(!_vm.fetchingActiveFilterParams && !_vm.activeFilterParams.length)?_c('p',[_vm._v("\n          Ingen kategorier\n        ")]):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mt-auto flex w-full flex-col border-t py-4 px-4 lg:border-t-0 lg:py-0 lg:px-6",class:{ 'lg:!border-t lg:!py-4': _vm.showBottomComponent }},[(_vm.showBottomComponent)?_c('div',{staticClass:"mb-4 border-b pb-4 lg:border-b-0 lg:pb-0"},[_c(_vm.bottomComponent,{tag:"component"})],1):_vm._e(),_vm._v(" "),_c('AccountBar')],1)],1):_vm._e(),_vm._v(" "),_c('Transition',{attrs:{"appear":"","name":"fadeIn"}},[(_vm.menuIsOpen)?_c('div',{staticClass:"bg-overlay fixed inset-0 z-[9] h-full w-full",on:{"click":function($event){_vm.restoreMenuState(_vm.$route)
        _vm.$store.commit('setMenuIsOpen', false)}}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }