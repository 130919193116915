export const state = () => ({
  editingDocument: null,
  newName: '',
  categories: [{ name: 'Alle', id: 1 }],
  visibilities: [{ name: 'Alle', id: 1 }],
  documents: [],
  documentsFiltered: [],
  currentCategory: null,
  ownerExtId: null,
})

export const mutations = {
  setEditingDocument(state, file) {
    state.editingDocument = file
  },
  clearEditingDocument(state) {
    state.editingDocument = null
  },

  setNewName(state, name) {
    state.newName = name
  },

  setCategories(state, categories) {
    state.categories = categories
  },

  setVisibilities(state, visibilities) {
    state.visibilities = visibilities
  },

  setDocuments(state, documents) {
    state.documents = documents
  },

  setDocumentsFiltered(state, documentsFiltered) {
    state.documentsFiltered = documentsFiltered
  },

  clearDocumentsFiltered(state) {
    state.documentsFiltered = null
  },

  setCurrentCategory(state, category) {
    state.currentCategory = category
  },

  setOwnerExtId(state, extId) {
    state.ownerExtId = extId
  },
}

export const getters = {
  editingDocument: state => state.editingDocument,
  categories: state => state.categories,
  visibilities: state => state.visibilities,
  documents: state => state.documents,
  documentsFiltered: state => state.documentsFiltered,
  newName: state => state.newName,
  currentCategory: state => state.currentCategory,
  ownerExtId: state => state.ownerExtId,

  owner: state => {
    const type = state.ownerExtId
      ? state.ownerExtId?.split('-')[1] === '0'
        ? 'organization'
        : 'department'
      : 'department'
    return {
      id: state.ownerExtId,
      type,
    }
  },
}
