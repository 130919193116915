var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-full lg:max-w-[470px]"},[_c('div',{staticClass:"relative flex h-10 items-center border border-black/10 transition-[border-radius,background-color]",class:[
      _vm.queryNotEmpty
        ? 'rounded-b-none rounded-t-[15px] bg-white'
        : 'rounded-[100px] bg-white lg:bg-gray-100' ]},[_c('div',{staticClass:"pointer-events-none absolute top-1/2 right-1 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-primary"},[_c('LoadSvg',{staticClass:"h-3 w-3",attrs:{"icon":"Search"}})],1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"block h-full w-full rounded-full px-5 py-2 text-base outline-none transition lg:w-80 lg:text-sm",class:[_vm.queryNotEmpty ? 'bg-white' : 'bg-white lg:bg-gray-100'],attrs:{"type":"text","placeholder":"Søg"},domProps:{"value":(_vm.query)},on:{"click":_vm.searchFocused,"touchend":_vm.searchFocused,"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}})]),_vm._v(" "),(_vm.queryNotEmpty)?_c('SearchDrawer',_vm._b({attrs:{"query":_vm.query}},'SearchDrawer',{
      isLoading: _vm.isLoading,
      query: _vm.query,
      departmentDocumentResults: _vm.departmentDocumentResults,
      tenantDocumentResults: _vm.tenantDocumentResults,
      documentResults: _vm.documentResults,
      handbookResults: _vm.handbookResults,
      pageResults: _vm.pageResults,
      sustainableActionsResults: _vm.sustainableActionsResults,
    },false)):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.queryNotEmpty)?_c('div',{staticClass:"bg-overlay fixed left-0 right-0 bottom-0 top-[var(--header-height)] z-[-1] block h-full w-full lg:h-[calc(100vh-var(--header-height))]",on:{"click":function($event){return _vm.closeSearch()}}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }