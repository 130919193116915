//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: { param: Object },
  data() {
    return {
      currentWindowQuery: '',
    }
  },
  computed: {
    ...mapGetters({
      department: 'department',
      owner: 'boardDocuments/owner',
    }),
    currentFilterId() {
      if (this.currentRouteQuery) {
        return parseInt(this.currentRouteQuery?.replaceAll('category_', ''))
      }

      const currentCategory =
        this.$store.getters['boardDocuments/currentCategory'] ||
        this.$store.getters['inbox/selectedCategory']
      return currentCategory?.id
    },
    name() {
      return this.param?.name
    },
    notifications() {
      return this.param?.unread
    },
    documentCount() {
      return this.param?.documentCount
    },
    count() {
      if (this.documentCount) return this.documentCount
      if (this.notifications > 0) return this.notifications
      return
    },
    icon() {
      return this.filter?.icon
    },
    hasIcon() {
      return this.icon
    },
    currentRouteQuery() {
      return (
        this.$route?.query?.filter ||
        window.location.search.replaceAll('?filter=', '')
      )
    },
    isActive() {
      return this.currentFilterId === this.param?.id
    },
    departmentId() {
      return this.department?.ext_department_id
    },
  },
  methods: {
    setParam() {
      const query = {
        filter: `category_${this.param.id}`,
        owner_id: this.owner.id,
      }
      this.$router.push({
        path: this.$route.path,
        query: query,
      })
    },
  },
}
