//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: String,
    selectId: String,
    options: Array,
    value: Number | String,
    showLabel: { type: Boolean, default: false },
  },
}
