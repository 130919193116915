export const AccessDeniedModal = () => import('../../components/AccessDeniedModal.vue' /* webpackChunkName: "components/access-denied-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountBar = () => import('../../components/AccountBar.vue' /* webpackChunkName: "components/account-bar" */).then(c => wrapFunctional(c.default || c))
export const AdministrativeHelpCard = () => import('../../components/AdministrativeHelpCard.vue' /* webpackChunkName: "components/administrative-help-card" */).then(c => wrapFunctional(c.default || c))
export const AdministrativeHelpHeader = () => import('../../components/AdministrativeHelpHeader.vue' /* webpackChunkName: "components/administrative-help-header" */).then(c => wrapFunctional(c.default || c))
export const AdministrativeHelpSimpleForm = () => import('../../components/AdministrativeHelpSimpleForm.vue' /* webpackChunkName: "components/administrative-help-simple-form" */).then(c => wrapFunctional(c.default || c))
export const ArticleSectionContentBlock = () => import('../../components/ArticleSectionContentBlock.vue' /* webpackChunkName: "components/article-section-content-block" */).then(c => wrapFunctional(c.default || c))
export const ArticleSectionImageBlock = () => import('../../components/ArticleSectionImageBlock.vue' /* webpackChunkName: "components/article-section-image-block" */).then(c => wrapFunctional(c.default || c))
export const ArticleSectionLinkBlock = () => import('../../components/ArticleSectionLinkBlock.vue' /* webpackChunkName: "components/article-section-link-block" */).then(c => wrapFunctional(c.default || c))
export const ArticleSectionVideoBlock = () => import('../../components/ArticleSectionVideoBlock.vue' /* webpackChunkName: "components/article-section-video-block" */).then(c => wrapFunctional(c.default || c))
export const ArticleSwiper = () => import('../../components/ArticleSwiper.vue' /* webpackChunkName: "components/article-swiper" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../components/Back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const BoardDocumentFilter = () => import('../../components/BoardDocumentFilter.vue' /* webpackChunkName: "components/board-document-filter" */).then(c => wrapFunctional(c.default || c))
export const BoardDocumentItem = () => import('../../components/BoardDocumentItem.vue' /* webpackChunkName: "components/board-document-item" */).then(c => wrapFunctional(c.default || c))
export const BoardDocuments = () => import('../../components/BoardDocuments.vue' /* webpackChunkName: "components/board-documents" */).then(c => wrapFunctional(c.default || c))
export const BoardDocumentsDrawer = () => import('../../components/BoardDocumentsDrawer.vue' /* webpackChunkName: "components/board-documents-drawer" */).then(c => wrapFunctional(c.default || c))
export const BoardDocumentsDrawerSubmenuHeader = () => import('../../components/BoardDocumentsDrawerSubmenuHeader.vue' /* webpackChunkName: "components/board-documents-drawer-submenu-header" */).then(c => wrapFunctional(c.default || c))
export const BoardDocumentsFilterList = () => import('../../components/BoardDocumentsFilterList.vue' /* webpackChunkName: "components/board-documents-filter-list" */).then(c => wrapFunctional(c.default || c))
export const BoardDocumentsHeader = () => import('../../components/BoardDocumentsHeader.vue' /* webpackChunkName: "components/board-documents-header" */).then(c => wrapFunctional(c.default || c))
export const BoardSelector = () => import('../../components/BoardSelector.vue' /* webpackChunkName: "components/board-selector" */).then(c => wrapFunctional(c.default || c))
export const ButtonComponent = () => import('../../components/ButtonComponent.vue' /* webpackChunkName: "components/button-component" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDeletionModal = () => import('../../components/ConfirmDeletionModal.vue' /* webpackChunkName: "components/confirm-deletion-modal" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContactDrawer = () => import('../../components/ContactDrawer.vue' /* webpackChunkName: "components/contact-drawer" */).then(c => wrapFunctional(c.default || c))
export const ContactInformation = () => import('../../components/ContactInformation.vue' /* webpackChunkName: "components/contact-information" */).then(c => wrapFunctional(c.default || c))
export const ContactInformationEditor = () => import('../../components/ContactInformationEditor.vue' /* webpackChunkName: "components/contact-information-editor" */).then(c => wrapFunctional(c.default || c))
export const ContactInformationForm = () => import('../../components/ContactInformationForm.vue' /* webpackChunkName: "components/contact-information-form" */).then(c => wrapFunctional(c.default || c))
export const ContactInformationFormFlow = () => import('../../components/ContactInformationFormFlow.vue' /* webpackChunkName: "components/contact-information-form-flow" */).then(c => wrapFunctional(c.default || c))
export const ContactInformationFormSubmitted = () => import('../../components/ContactInformationFormSubmitted.vue' /* webpackChunkName: "components/contact-information-form-submitted" */).then(c => wrapFunctional(c.default || c))
export const ContactInformationSummaryDrawer = () => import('../../components/ContactInformationSummaryDrawer.vue' /* webpackChunkName: "components/contact-information-summary-drawer" */).then(c => wrapFunctional(c.default || c))
export const CornerGradient = () => import('../../components/CornerGradient.vue' /* webpackChunkName: "components/corner-gradient" */).then(c => wrapFunctional(c.default || c))
export const CreateCategoryButton = () => import('../../components/CreateCategoryButton.vue' /* webpackChunkName: "components/create-category-button" */).then(c => wrapFunctional(c.default || c))
export const CreateCategoryModal = () => import('../../components/CreateCategoryModal.vue' /* webpackChunkName: "components/create-category-modal" */).then(c => wrapFunctional(c.default || c))
export const DeleteCategoryModal = () => import('../../components/DeleteCategoryModal.vue' /* webpackChunkName: "components/delete-category-modal" */).then(c => wrapFunctional(c.default || c))
export const DepartmentBoardInfo = () => import('../../components/DepartmentBoardInfo.vue' /* webpackChunkName: "components/department-board-info" */).then(c => wrapFunctional(c.default || c))
export const DepartmentHeader = () => import('../../components/DepartmentHeader.vue' /* webpackChunkName: "components/department-header" */).then(c => wrapFunctional(c.default || c))
export const DepartmentHeaderGhost = () => import('../../components/DepartmentHeaderGhost.vue' /* webpackChunkName: "components/department-header-ghost" */).then(c => wrapFunctional(c.default || c))
export const DocumentFlyOut = () => import('../../components/DocumentFlyOut.vue' /* webpackChunkName: "components/document-fly-out" */).then(c => wrapFunctional(c.default || c))
export const DocumentTableHeader = () => import('../../components/DocumentTableHeader.vue' /* webpackChunkName: "components/document-table-header" */).then(c => wrapFunctional(c.default || c))
export const DocumentToast = () => import('../../components/DocumentToast.vue' /* webpackChunkName: "components/document-toast" */).then(c => wrapFunctional(c.default || c))
export const Drawer = () => import('../../components/Drawer.vue' /* webpackChunkName: "components/drawer" */).then(c => wrapFunctional(c.default || c))
export const DrawerHeader = () => import('../../components/DrawerHeader.vue' /* webpackChunkName: "components/drawer-header" */).then(c => wrapFunctional(c.default || c))
export const DynamicDrawer = () => import('../../components/DynamicDrawer.vue' /* webpackChunkName: "components/dynamic-drawer" */).then(c => wrapFunctional(c.default || c))
export const DynamicModal = () => import('../../components/DynamicModal.vue' /* webpackChunkName: "components/dynamic-modal" */).then(c => wrapFunctional(c.default || c))
export const DynamicToast = () => import('../../components/DynamicToast.vue' /* webpackChunkName: "components/dynamic-toast" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const ErrorToast = () => import('../../components/ErrorToast.vue' /* webpackChunkName: "components/error-toast" */).then(c => wrapFunctional(c.default || c))
export const EtrayEmbed = () => import('../../components/EtrayEmbed.vue' /* webpackChunkName: "components/etray-embed" */).then(c => wrapFunctional(c.default || c))
export const FeaturedArticles = () => import('../../components/FeaturedArticles.vue' /* webpackChunkName: "components/featured-articles" */).then(c => wrapFunctional(c.default || c))
export const FeaturedArticlesGhost = () => import('../../components/FeaturedArticlesGhost.vue' /* webpackChunkName: "components/featured-articles-ghost" */).then(c => wrapFunctional(c.default || c))
export const FeaturedFile = () => import('../../components/FeaturedFile.vue' /* webpackChunkName: "components/featured-file" */).then(c => wrapFunctional(c.default || c))
export const FeaturedFileGhost = () => import('../../components/FeaturedFileGhost.vue' /* webpackChunkName: "components/featured-file-ghost" */).then(c => wrapFunctional(c.default || c))
export const FeaturedFiles = () => import('../../components/FeaturedFiles.vue' /* webpackChunkName: "components/featured-files" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../components/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FilterButton = () => import('../../components/FilterButton.vue' /* webpackChunkName: "components/filter-button" */).then(c => wrapFunctional(c.default || c))
export const FindDepartmentForm = () => import('../../components/FindDepartmentForm.vue' /* webpackChunkName: "components/find-department-form" */).then(c => wrapFunctional(c.default || c))
export const FindDepartmentHeader = () => import('../../components/FindDepartmentHeader.vue' /* webpackChunkName: "components/find-department-header" */).then(c => wrapFunctional(c.default || c))
export const FindDepartmentItem = () => import('../../components/FindDepartmentItem.vue' /* webpackChunkName: "components/find-department-item" */).then(c => wrapFunctional(c.default || c))
export const ForgotPasswordForm = () => import('../../components/ForgotPasswordForm.vue' /* webpackChunkName: "components/forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const ForgotPasswordHeader = () => import('../../components/ForgotPasswordHeader.vue' /* webpackChunkName: "components/forgot-password-header" */).then(c => wrapFunctional(c.default || c))
export const GhostText = () => import('../../components/GhostText.vue' /* webpackChunkName: "components/ghost-text" */).then(c => wrapFunctional(c.default || c))
export const GoogleTranslateLogic = () => import('../../components/GoogleTranslateLogic.vue' /* webpackChunkName: "components/google-translate-logic" */).then(c => wrapFunctional(c.default || c))
export const GoogleTranslateWidget = () => import('../../components/GoogleTranslateWidget.vue' /* webpackChunkName: "components/google-translate-widget" */).then(c => wrapFunctional(c.default || c))
export const HandbookArticle = () => import('../../components/HandbookArticle.vue' /* webpackChunkName: "components/handbook-article" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../components/Img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const InfoBoxModal = () => import('../../components/InfoBoxModal.vue' /* webpackChunkName: "components/info-box-modal" */).then(c => wrapFunctional(c.default || c))
export const InputSelect = () => import('../../components/InputSelect.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/InputText.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const LanguageAccountInfo = () => import('../../components/LanguageAccountInfo.vue' /* webpackChunkName: "components/language-account-info" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const LoadSvg = () => import('../../components/LoadSvg.vue' /* webpackChunkName: "components/load-svg" */).then(c => wrapFunctional(c.default || c))
export const LoadingSpinner = () => import('../../components/LoadingSpinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const LoginHeader = () => import('../../components/LoginHeader.vue' /* webpackChunkName: "components/login-header" */).then(c => wrapFunctional(c.default || c))
export const LoginToast = () => import('../../components/LoginToast.vue' /* webpackChunkName: "components/login-toast" */).then(c => wrapFunctional(c.default || c))
export const Message = () => import('../../components/Message.vue' /* webpackChunkName: "components/message" */).then(c => wrapFunctional(c.default || c))
export const MessageListItem = () => import('../../components/MessageListItem.vue' /* webpackChunkName: "components/message-list-item" */).then(c => wrapFunctional(c.default || c))
export const MobileDocumentAction = () => import('../../components/MobileDocumentAction.vue' /* webpackChunkName: "components/mobile-document-action" */).then(c => wrapFunctional(c.default || c))
export const MobileDocumentSubAction = () => import('../../components/MobileDocumentSubAction.vue' /* webpackChunkName: "components/mobile-document-sub-action" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalInput = () => import('../../components/ModalInput.vue' /* webpackChunkName: "components/modal-input" */).then(c => wrapFunctional(c.default || c))
export const ModalSelect = () => import('../../components/ModalSelect.vue' /* webpackChunkName: "components/modal-select" */).then(c => wrapFunctional(c.default || c))
export const MultiStepForm = () => import('../../components/MultiStepForm.vue' /* webpackChunkName: "components/multi-step-form" */).then(c => wrapFunctional(c.default || c))
export const MultiStepFormFlow = () => import('../../components/MultiStepFormFlow.vue' /* webpackChunkName: "components/multi-step-form-flow" */).then(c => wrapFunctional(c.default || c))
export const MultiStepFormSubmitted = () => import('../../components/MultiStepFormSubmitted.vue' /* webpackChunkName: "components/multi-step-form-submitted" */).then(c => wrapFunctional(c.default || c))
export const MultiStepFormSummary = () => import('../../components/MultiStepFormSummary.vue' /* webpackChunkName: "components/multi-step-form-summary" */).then(c => wrapFunctional(c.default || c))
export const MyUnitHeader = () => import('../../components/MyUnitHeader.vue' /* webpackChunkName: "components/my-unit-header" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NotLoggedInModal = () => import('../../components/NotLoggedInModal.vue' /* webpackChunkName: "components/not-logged-in-modal" */).then(c => wrapFunctional(c.default || c))
export const PageLinks = () => import('../../components/PageLinks.vue' /* webpackChunkName: "components/page-links" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/PageLoader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const ProfileAccordion = () => import('../../components/ProfileAccordion.vue' /* webpackChunkName: "components/profile-accordion" */).then(c => wrapFunctional(c.default || c))
export const ProfileDataSection = () => import('../../components/ProfileDataSection.vue' /* webpackChunkName: "components/profile-data-section" */).then(c => wrapFunctional(c.default || c))
export const RenameDocumentModal = () => import('../../components/RenameDocumentModal.vue' /* webpackChunkName: "components/rename-document-modal" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordForm = () => import('../../components/ResetPasswordForm.vue' /* webpackChunkName: "components/reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordHeader = () => import('../../components/ResetPasswordHeader.vue' /* webpackChunkName: "components/reset-password-header" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchDrawer = () => import('../../components/SearchDrawer.vue' /* webpackChunkName: "components/search-drawer" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const Seperator = () => import('../../components/Seperator.vue' /* webpackChunkName: "components/seperator" */).then(c => wrapFunctional(c.default || c))
export const ShortcutItem = () => import('../../components/ShortcutItem.vue' /* webpackChunkName: "components/shortcut-item" */).then(c => wrapFunctional(c.default || c))
export const Shortcuts = () => import('../../components/Shortcuts.vue' /* webpackChunkName: "components/shortcuts" */).then(c => wrapFunctional(c.default || c))
export const SidebarButton = () => import('../../components/SidebarButton.vue' /* webpackChunkName: "components/sidebar-button" */).then(c => wrapFunctional(c.default || c))
export const SidebarLink = () => import('../../components/SidebarLink.vue' /* webpackChunkName: "components/sidebar-link" */).then(c => wrapFunctional(c.default || c))
export const SidebarParam = () => import('../../components/SidebarParam.vue' /* webpackChunkName: "components/sidebar-param" */).then(c => wrapFunctional(c.default || c))
export const SmartLink = () => import('../../components/SmartLink.vue' /* webpackChunkName: "components/smart-link" */).then(c => wrapFunctional(c.default || c))
export const StatCard = () => import('../../components/StatCard.vue' /* webpackChunkName: "components/stat-card" */).then(c => wrapFunctional(c.default || c))
export const Stats = () => import('../../components/Stats.vue' /* webpackChunkName: "components/stats" */).then(c => wrapFunctional(c.default || c))
export const StatsSwiper = () => import('../../components/StatsSwiper.vue' /* webpackChunkName: "components/stats-swiper" */).then(c => wrapFunctional(c.default || c))
export const SummaryDrawer = () => import('../../components/SummaryDrawer.vue' /* webpackChunkName: "components/summary-drawer" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionBoardInfo = () => import('../../components/SustainableActionBoardInfo.vue' /* webpackChunkName: "components/sustainable-action-board-info" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsCard = () => import('../../components/SustainableActionsCard.vue' /* webpackChunkName: "components/sustainable-actions-card" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsCategories = () => import('../../components/SustainableActionsCategories.vue' /* webpackChunkName: "components/sustainable-actions-categories" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsCategory = () => import('../../components/SustainableActionsCategory.vue' /* webpackChunkName: "components/sustainable-actions-category" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsDrawer = () => import('../../components/SustainableActionsDrawer.vue' /* webpackChunkName: "components/sustainable-actions-drawer" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsForm = () => import('../../components/SustainableActionsForm.vue' /* webpackChunkName: "components/sustainable-actions-form" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsFormModal = () => import('../../components/SustainableActionsFormModal.vue' /* webpackChunkName: "components/sustainable-actions-form-modal" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsGrid = () => import('../../components/SustainableActionsGrid.vue' /* webpackChunkName: "components/sustainable-actions-grid" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsHeader = () => import('../../components/SustainableActionsHeader.vue' /* webpackChunkName: "components/sustainable-actions-header" */).then(c => wrapFunctional(c.default || c))
export const SustainableActionsSubmitButton = () => import('../../components/SustainableActionsSubmitButton.vue' /* webpackChunkName: "components/sustainable-actions-submit-button" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../components/Swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const TabHeader = () => import('../../components/TabHeader.vue' /* webpackChunkName: "components/tab-header" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../components/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const TableFooter = () => import('../../components/TableFooter.vue' /* webpackChunkName: "components/table-footer" */).then(c => wrapFunctional(c.default || c))
export const TableGhost = () => import('../../components/TableGhost.vue' /* webpackChunkName: "components/table-ghost" */).then(c => wrapFunctional(c.default || c))
export const TableHeader = () => import('../../components/TableHeader.vue' /* webpackChunkName: "components/table-header" */).then(c => wrapFunctional(c.default || c))
export const TableRow = () => import('../../components/TableRow.vue' /* webpackChunkName: "components/table-row" */).then(c => wrapFunctional(c.default || c))
export const TechnicalIssuesModal = () => import('../../components/TechnicalIssuesModal.vue' /* webpackChunkName: "components/technical-issues-modal" */).then(c => wrapFunctional(c.default || c))
export const Timeline = () => import('../../components/Timeline.vue' /* webpackChunkName: "components/timeline" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/Toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const TransitionDocumentItems = () => import('../../components/TransitionDocumentItems.vue' /* webpackChunkName: "components/transition-document-items" */).then(c => wrapFunctional(c.default || c))
export const TransitionFadeIn = () => import('../../components/TransitionFadeIn.vue' /* webpackChunkName: "components/transition-fade-in" */).then(c => wrapFunctional(c.default || c))
export const TransitionFeaturedArticles = () => import('../../components/TransitionFeaturedArticles.vue' /* webpackChunkName: "components/transition-featured-articles" */).then(c => wrapFunctional(c.default || c))
export const TransitionFeaturedFiles = () => import('../../components/TransitionFeaturedFiles.vue' /* webpackChunkName: "components/transition-featured-files" */).then(c => wrapFunctional(c.default || c))
export const TransitionLogin = () => import('../../components/TransitionLogin.vue' /* webpackChunkName: "components/transition-login" */).then(c => wrapFunctional(c.default || c))
export const TransitionMessages = () => import('../../components/TransitionMessages.vue' /* webpackChunkName: "components/transition-messages" */).then(c => wrapFunctional(c.default || c))
export const TransitionNavigationRootMenu = () => import('../../components/TransitionNavigationRootMenu.vue' /* webpackChunkName: "components/transition-navigation-root-menu" */).then(c => wrapFunctional(c.default || c))
export const TransitionProfileAccordion = () => import('../../components/TransitionProfileAccordion.vue' /* webpackChunkName: "components/transition-profile-accordion" */).then(c => wrapFunctional(c.default || c))
export const TransitionShortcuts = () => import('../../components/TransitionShortcuts.vue' /* webpackChunkName: "components/transition-shortcuts" */).then(c => wrapFunctional(c.default || c))
export const TransitionStatCards = () => import('../../components/TransitionStatCards.vue' /* webpackChunkName: "components/transition-stat-cards" */).then(c => wrapFunctional(c.default || c))
export const UploadDocumentModal = () => import('../../components/UploadDocumentModal.vue' /* webpackChunkName: "components/upload-document-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
