//
//
//
//

export default {
  props: {
    icon: {
      type: String,
      required: true,
      default: 'Close circle', // Placeholder, icon should always be supplied
    },
  },
  computed: {
    iconSrc() {
      return require(`!svg-inline-loader?classPrefix!@/assets/svg/${this.icon}.svg`)
    },
  },
}
