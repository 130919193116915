export const state = () => ({
  selectedMessage: null,
  selectedCategory: null,
  totalMessages: {},
  unreadMessages: {},
})

export const mutations = {
  setSelectedMessage(state, message) {
    state.selectedMessage = message
  },
  setSelectedCategory(state, category) {
    state.selectedCategory = category
  },
  setTotalMessages(state, value) {
    state.totalMessages = value
  },
  setUnreadMessages(state, value) {
    state.unreadMessages = value
  },
}

export const actions = {
  async getCount(context) {
    const counts = await this.$api.tenantGetNotificationCount()
    const totalMessages = counts?.data?.total
    const unreadMessages = counts?.data?.unread
    context.commit('setTotalMessages', totalMessages)
    context.commit('setUnreadMessages', unreadMessages)
  },
}

export const getters = {
  selectedMessage: state => state?.selectedMessage,
  selectedCategory: state => state?.selectedCategory,
  totalMessages: state => state?.totalMessages,
  unreadMessages: state => state?.unreadMessages,
}
