//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      currentTenant: 'user/currentTenant',
      userInfo: 'user/userInfo',
      token: 'auth/getToken',
    }),
    isLoggedIn() {
      return this.token != null
    },
    tenantName() {
      return this.currentTenant?.personNavn?.trim()
    },
    tenantInitials() {
      const tenantNameSplit = this.tenantName?.split(' ')
      const lastName = tenantNameSplit?.[tenantNameSplit?.length - 1]
      const firstName = this.tenantName?.replace(lastName, '')
      return `${firstName?.[0]}${lastName?.[0]}`
    },
    apartmentNumber() {
      return this.userInfo?.data?.tenant?.lejerStreng
    },
    tenantNumber() {
      return this.$t('general.tenant-number').replaceAll(
        '{{ TENANT_NUMBER }}',
        this.apartmentNumber
      )
    },
  },
  methods: {
    async logOut() {
      this.$router.push({ path: '/' })
      await this.$store.dispatch('user/logOut')
      this.$store.dispatch('auth/clearCookies')

      this.$store.commit('auth/setToken', null)
      this.$nextTick(() => {
        this.$store.commit('setDepartment', null)
        this.$store.commit('setDepartmentExtData', null)
        this.$store.commit('setTheme', null)
      })
    },
  },
}
