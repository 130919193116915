//
//
//
//

export default {
  props: {
    width: { type: String, default: () => '50' },
    height: { type: String, default: () => '50' },
  },
  computed: {
    widthStyle() {
      return `width: ${this.width}px`
    },
    heightStyle() {
      return `height: ${this.height}px`
    },
    style() {
      return `${this.widthStyle}; ${this.heightStyle}`
    },
  },
}
