//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      showingFlyout: false,
    }
  },
  computed: {
    ...mapGetters({
      currentTenant: 'user/currentTenant',
      userInfo: 'user/userInfo',
      firstName: 'user/firstName',
      lastName: 'user/lastName',
      apartmentNumber: 'user/apartmentNumber',
      email: 'user/email',
      phone: 'user/phone',
      english: 'translateToEnglish',
    }),
    isExternalPerson() {
      return this.userInfo?.data?.external_person
    },
    userInitials() {
      return this.user?.firstName?.[0] + this.user?.lastName?.[0]
    },
    user() {
      if (!this.userInfo?.data) return null
      const tenantName = this.tenantName
      const tenantNameSplit = tenantName?.split(' ')
      const lastName = tenantNameSplit
        ? tenantNameSplit?.[tenantNameSplit?.length - 1]
        : 'A'
      const firstName = tenantName ? tenantName?.replace(lastName, '') : 'A'
      return {
        firstName: firstName,
        lastName: lastName,
        apartmentNumber: this.userInfo?.data?.lejerStreng,
        email: this.firstTenant?.personEmail,
        phone: this.firstTenant?.personMobil,
      }
    },
    tenantName() {
      if (this.isExternalPerson) {
        return this.userInfo?.data?.external_person?.personNavn
      }
      return this.currentTenant?.personNavn?.trim()
    },
    apartmentNumber() {
      return this.userInfo?.data?.tenant?.lejerStreng
    },
    tenantNumber() {
      return this.$t('general.tenant-number').replaceAll(
        '{{ TENANT_NUMBER }}',
        this.apartmentNumber
      )
    },
  },
  methods: {
    toggleFlyout() {
      this.showingFlyout = !this.showingFlyout

      if (this.showingFlyout) {
        document.querySelector('body').classList.add('show-google-translate')
        this.initialScrollY = window.scrollY
        this.setScrollListener()
      } else {
        document
          .querySelector('body')
          .classList.remove('show-google-translate')
        this.initialScrollY = 0
        this.removeScrollListener()
      }
    },
    handleScroll() {
      const scrolledDown = window.scrollY > this.initialScrollY + 100
      if (scrolledDown) this.toggleFlyout()

      const scrolledUp = window.scrollY < this.initialScrollY - 100
      if (scrolledUp) this.toggleFlyout()
    },
    setScrollListener() {
      window.addEventListener('scroll', this.handleScroll)
    },
    removeScrollListener() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    async logOut() {
      this.$router.push({ path: '/' })
      await this.$store.dispatch('user/logOut')
      this.$store.dispatch('auth/clearCookies')

      this.$store.commit('auth/setToken', null)
      this.$nextTick(() => {
        this.$store.commit('setDepartment', null)
        this.$store.commit('setDepartmentExtData', null)
        this.$store.commit('setTheme', null)
        this.toggleFlyout()
      })
    },
    toggleEnglish() {
      // TO-DO: Store & fetch from LocalStorage to better preserve user preferences.
      this.$store.commit('setTranslateToEnglish', !this.english)
    },
  },
  watch: {
    $route(newRoute) {
      if (this.showingFlyout) this.toggleFlyout()
    },
  },
}
