export const state = () => ({
  uploadFile: null,
  category: null,
  visibility: null,
})

export const mutations = {
  setUploadFile(state, file) {
    state.uploadFile = file
  },
  clearUploadFile(state) {
    state.uploadFile = null
  },

  setCategory(state, category) {
    state.category = category
  },
  clearCategory(state) {
    state.category = null
  },

  setVisibility(state, visibility) {
    state.visibility = visibility
  },
  clearVisibility(state) {
    state.visibility = null
  },
}

export const getters = {
  uploadFile: state => state.uploadFile,
  category: state => state.category,
  visibility: state => state.visibility,
}
