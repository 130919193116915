//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: { link: Object },
  computed: {
    tenantIsBoardMember() {
      const currentTenant = this.$store.getters['user/currentTenant']
      const isPartOfBoard = currentTenant?.roller?.length > 0
      return isPartOfBoard
    },
    isLoggedIn() {
      return this.$store.getters['auth/getToken'] !== null
    },
    linkIsPublic() {
      return this.link?.meta?.public
    },
    lockLink() {
      if (
        this.link?.name === 'department-documents-board-documents' &&
        this.$route.name === 'department-documents-department-documents'
      )
        return false // Links to board-documents, if present should always be public

      if (!this.isLoggedIn && !this.linkIsPublic) return true

      // Check if link is to board page
      if (this.link?.name === 'department-board' && !this.tenantIsBoardMember)
        return true
      return false
    },
    hasChildren() {
      return this.link?.meta?.children
    },
    hasFilter() {
      return this.link?.filterValue
    },
    parsedLink() {
      if (this.hasFilter) {
        return {
          name: this.link?.name,
          query: { filter: `category_${this.link?.filterValue}` },
        }
      }

      return { name: this.link?.name }
    },
    icon() {
      return this.link?.meta?.icon
    },
    title() {
      return this.link?.meta?.title || this.link?.title
    },
    count() {
      if (this.link?.documentCount) return this.link?.documentCount

      if (!this.isInbox) return null

      const notifications = this.$store.getters['inbox/unreadMessages']
      if (!notifications) return
      return notifications?.count
    },
    isActive() {
      return this.link?.name === this.$route?.name
    },
    isInbox() {
      return this.link?.name === 'department-inbox'
    },
    childFiltersPresent() {
      if (
        this.link?.name === 'department-documents-department-documents' ||
        this.link?.name === 'department-documents-my-documents'
      )
        return true
      return false
    },
    departmentBoligfyVersion() {
      return this.$store.getters['boligfyVersion']
    },
    boligfyVersion() {
      return this.link?.meta?.boligfyVersion || null
    },
    boligfyVersionMatches() {
      if (!this.boligfyVersion) return true
      if (this.departmentBoligfyVersion === 'full') return true
      return this.departmentBoligfyVersion === this.boligfyVersion
    },
  },
  methods: {
    handleClick() {
      if (this.lockLink) {
        let title = 'Log ind'

        if (this.link?.name === 'department-board' && this.isLoggedIn)
          title = 'Ingen adgang'
        this.$store.commit('modal/setModal', {
          type: 'NotLoggedInModal',
          data: {
            icon: 'log-in',
            title: title,
          },
          disableOverlayClick: true,
        })
        return
      }

      this.$router.push(this.parsedLink)
    },
  },
}
