import https from 'https'
export default ({ $axios, redirect, app, store }, inject) => {
  const api = $axios.create({
    baseURL: process.env.NUXT_ENV_API_URL,
    timeout: 30000,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  })

  api.onRequest(config => {
    const accessToken =
      app.$cookies.get('boligfy-auth') || store.getters['auth/getToken']

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
  })

  const errors = {
    404: {
      title: '404 - Siden kunne ikke findes',
      description: 'Vi kunne ikke finde den side du ledte efter',
    },
    500: {
      title: '500 - Noget gik galt',
      description:
        'Vi oplever problemer men arbejder på sagen. Prøv venligts igen senere',
    },
  }

  const errorHandler = (axiosError, endpoint, query) => {
    const statusCode = axiosError?.status

    console.error('ERROR timestamp', new Date())
    console.error('Happening on endpoint', endpoint)
    console.error('Axios response error', axiosError)

    // Inform Bugsnag of error
    app.$bugsnag.notify({
      name: `/api/${endpoint} | HTTP ${statusCode}`,
      message: `Error message: ${axiosError?.data?.message}`,
    })

    // Attempt to grab user-friendly error message based on code, and display toast.
    let errorMessage = errors[axiosError?.status]

    if (errorMessage) {
      let error = {}
      error.statusCode = statusCode
      error.message = errorMessage

      store.commit('toast/setToast', {
        type: 'ErrorToast',
        data: {
          title: errorMessage.title,
          subtitle: errorMessage.description,
        },
      })
      // Prevent backend errors from killing frontend, and redirecting to error page.
      if (statusCode !== 500) throw error
    }
  }

  const get = async ({ endpoint, params, query }) => {
    try {
      const response = await api.get(endpoint, { params })
      return response?.data
    } catch (error) {
      console.log(`GET ERROR, in: ${endpoint}`)
      console.log(error)
      errorHandler(error.response, endpoint)
    }
  }

  const post = async (endpoint, params) => {
    try {
      const response = await api.post(endpoint, params)
      return response.data
    } catch (error) {
      console.log(`POST ERROR, in: ${endpoint}`)
      console.log(error)
      errorHandler(error.response, endpoint)
    }
  }

  const del = async (endpoint, params) => {
    try {
      const response = await api.delete(endpoint, params)
      return response?.data
    } catch (error) {
      console.log(`DELETE ERROR, in: ${endpoint}`)
      console.log(error)
      errorHandler(error.response, endpoint)
    }
  }

  const endpoints = {
    async login(body) {
      const endpoint = `auth/login`
      return post(endpoint, body)
    },
    async authOID(body) {
      app.$bugsnag.leaveBreadcrumb(
        'OID posted',
        {
          oid: body.code ?? 'unknown',
        },
        'request'
      )
      const endpoint = `auth/oid`
      return post(endpoint, body)
    },
    async forgotPassword(body) {
      const endpoint = `auth/forgot-password`
      return post(endpoint, body)
    },
    async resetPassword(body) {
      const endpoint = `auth/reset-password`
      return post(endpoint, body)
    },
    async theme(departmentName) {
      const endpoint = `department/${departmentName}/theme`
      return get({ endpoint })
    },
    async maintenance() {
      const endpoint = `maintenance`
      return get({ endpoint })
    },
    // Articles
    async articles() {
      const endpoint = `articles`
      return get({ endpoint })
    },
    async departmentArticles(id) {
      const endpoint = `department/${id}/articles`
      return get({ endpoint })
    },
    async articleBySlug(slug) {
      const endpoint = `articles/${slug}`
      return get({ endpoint })
    },
    async relatedArticlesById(id) {
      const endpoint = `articles/${id}/related`
      return get({ endpoint })
    },
    async relatedArticlesBySlug(slug) {
      const endpoint = `related-articles/${slug}`
      return get({ endpoint })
    },
    // Board
    async boardDocuments(owner) {
      const owner_id = owner.id
      const owner_type = owner.type
      const endpoint = `documents?owner_id=${owner_id}&owner_type=${owner_type}`
      return get({ endpoint })
    },
    async boardDocumentsByDepartmentId(ext_id) {
      const endpoint = `documents/department/${ext_id}`
      return get({ endpoint })
    },
    async boardCategories(owner_id, owner_type = 'department') {
      const endpoint = `categories?owner_id=${owner_id}&owner_type=${owner_type}`
      return get({ endpoint })
    },
    async boardVisibilities() {
      const endpoint = `documents/visibilities`
      return get({ endpoint })
    },
    async getBoardOptions(boardOptions) {
      // Filters boardOptions gathered from UnikBolig to only return ones that are enrolled in Boligfy
      const endpoint = `boards`
      const body = boardOptions
      return post(endpoint, body)
    },
    // Department
    async department() {
      const endpoint = `department`
      return get({ endpoint })
    },
    async departmentBoardByExtId(extId) {
      const endpoint = `department/ext/${extId}/board`
      return get({ endpoint })
    },
    async departmentDocumentsByExtId(extId) {
      const endpoint = `external_documents/department/${extId}`
      return get({ endpoint })
    },
    async departmentBySlug(slug) {
      const endpoint = `department/slug/${slug}`
      return get({ endpoint })
    },
    async departmentsByZip(zip) {
      const endpoint = `department/zip/${zip}`
      return get({ endpoint })
    },
    async departmentExtDataById(extId) {
      const endpoint = `department/ext/${extId}`
      return get({ endpoint })
    },
    async departmentThemeBySlug(slug) {
      const endpoint = `department/${slug}?theme`
      return get({ endpoint })
    },
    async departmentServiceCenter(id) {
      const endpoint = `contact/${id}`
      return get({ endpoint })
    },
    // Company
    async companyExtDataById(extId) {
      const endpoint = `company/ext/${extId}`
      return get({ endpoint })
    },
    // Documents
    async downloadExternalDocument(departmentExtId, documentId) {
      const endpoint = `departments/${departmentExtId}/external_documents?id=${documentId}`
      const url = `${process.env.NUXT_ENV_API_URL}${endpoint}`
      const accessToken =
        app.$cookies.get('boligfy-auth') || store.getters['auth/getToken']

      const response = await $axios({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
        url: url,
      })
      return response
    },

    async uploadDocument(body) {
      const endpoint = 'documents'
      return post(endpoint, body)
    },

    async deleteDocument(documentId) {
      const endpoint = `documents/delete/${documentId}`
      return post(endpoint)
    },

    async createCategory(body) {
      const endpoint = `categories`
      return post(endpoint, body)
    },

    async deleteCategory(category_id) {
      const endpoint = `categories/${category_id}`
      return del(endpoint)
    },

    async changeCategory(documentId, body) {
      const endpoint = `documents/move/${documentId}`
      return post(endpoint, body)
    },

    async changeVisibility(documentId, body) {
      const endpoint = `documents/visibility/${documentId}`
      return post(endpoint, body)
    },

    async renameDocument(documentId, body) {
      const endpoint = `documents/rename/${documentId}`
      return post(endpoint, body)
    },

    // Tenant
    async tenant() {
      const endpoint = `tenant`
      return get({ endpoint })
    },
    async tenantProfile() {
      const endpoint = 'tenant/profile'
      return get({ endpoint })
    },
    async updateTenantProfile(body) {
      const endpoint = `tenant/profile`
      return post(endpoint, body)
    },
    async tenantExternalDocuments() {
      const endpoint = `external_documents/tenant`
      return get({ endpoint })
    },
    async tenantDownloadExternalDocument(mimeType, id) {
      const endpoint = `external_documents?mimeType=${mimeType}&id=${id}`
      return get({ endpoint })
    },
    async tenantMaintenanceByYear(year) {
      // Date format is YYYY-MM-DD
      const endpoint = `tenant/maintenance?date=${year}-01-01`
      return get({ endpoint })
    },
    async tenantMaintenancePeriods() {
      const endpoint = `tenant/maintenance-all`
      return get({ endpoint })
    },
    async tenantRentByYearMonth(year, month) {
      // Date format is YYYY-MM-DD
      const endpoint = `tenant/rent?date=${year}-${month}-01`
      return get({ endpoint })
    },
    async tenantRentIndex() {
      const endpoint = `tenant/rent-index`
      return get({ endpoint })
    },
    async tenantSpending() {
      const endpoint = `tenant/spending?date=2022-04-01`
      return get({ endpoint })
    },
    async tenantWashingExpensesPeriods() {
      const endpoint = `tenant/washing-expenses-all`
      return get({ endpoint })
    },
    async tenantWashingExpensesByYearMonth(year, month) {
      // Date format is YYYY-MM-DD
      const endpoint = `tenant/washing-expenses?date=${year}-${month}-01`
      return get({ endpoint })
    },

    async tenantGetNotifications() {
      const endpoint = `tenant/notifications`
      return get({ endpoint })
    },
    async tenantGetNotificationCount() {
      const endpoint = `notifications/counts`
      return get({ endpoint })
    },
    async tenantMarkNotificationAsRead(notificationId) {
      const endpoint = `notifications/${notificationId}/markAsRead`
      return post(endpoint)
    },

    // Sustainable Actions
    async sustainableActions() {
      const endpoint = `sustainable-actions`
      return get({ endpoint })
    },

    async sustainableAction(id) {
      const endpoint = `sustainable-actions/${id}`
      return get({ endpoint })
    },

    async submitSustainableAction(body) {
      const endpoint = `sustainable-actions`
      // Prep a FormData object and map body to it
      let formData = new FormData()
      for (const key in body) {
        if (key === 'files[]') {
          continue
        } else {
          if (body[key]) formData.append(key, body[key])
        }
      }

      body['files[]']?.map(file => {
        formData.append('files[]', file)
      })

      // Post formData w/ Content-Type multipart/form-data
      return api.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },

    async sustainableCategories() {
      const endpoint = `sustainable-categories`
      return get({ endpoint })
    },

    // Search
    async search(body) {
      const endpoint = `search`
      return post(endpoint, body)
    },

    // Inquiries (EazyProject & Fallback email)
    async sendInquiry(body) {
      const endpoint = `inquiries`
      // Prep a FormData object and map body to it
      let formData = new FormData()
      for (const key in body) {
        if (body[key]) formData.append(key, body[key])
      }

      // Post formData w/ Content-Type multipart/form-data
      return api.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  }

  inject('api', endpoints)
}
