import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/tailwind.config.js'

const { theme } = resolveConfig(tailwindConfig)

export const state = () => ({
  size: {
    ...Object.entries(theme.screens).reduce((acc, [key, val]) => {
      acc[key] = false
      return acc
    }, {}),
  },
})
export const getters = {
  size: state => state.size,
  ...Object.entries(theme.screens).reduce((acc, [key, val]) => {
    acc[key] = state => state.size[key]
    return acc
  }, {}),
}
export const mutations = {
  setSize(state, { key, value }) {
    state.size[key] = value
  },
}
export const actions = {
  setSize({ commit }, { key, value }) {
    commit('setSize', { key, value })
  },
}
