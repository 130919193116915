export const state = () => ({
  type: null,
  data: {},
})

export const mutations = {
  setToast(state, { type, data }) {
    state.type = type
    state.data = data
  },
  closeToast(state) {
    state.type = null
    state.data = {}
  },
}

export const getters = {
  type: state => state.type,
  data: state => state.data,
}
