export const state = () => ({
  currentParent: null,
})

export const mutations = {
  setCurrentParent(state, message) {
    state.currentParent = message
  },
}

export const getters = {
  currentParent: state => state?.currentParent,
}
