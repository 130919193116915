/**
 * Downloads document from UB4 as .pdf
 * @param  {string} title Title of document to download
 * @param  {string} externalDepartmentId External Department id
 * @param  {string} externalDocumentReference UB4 note id
 */

export default ({ app, store }, inject) => {
  const setToast = data => {
    store.commit('toast/setToast', data)
  }

  const handleError = (error = null, externalDocumentReference) => {
    if (error) {
      console.log(`Couldn't download document '${externalDocumentReference}':`)
      console.error(error)
      this.$bugsnag.notify(new Error(error))
    }

    setToast({
      type: 'ErrorToast',
      data: {
        title: 'Kunne ikke downloade',
        subtitle: this.$t('general.toast_error'),
      },
    })
  }

  const download = async (
    title,
    externalDepartmentId,
    externalDocumentReference
  ) => {
    let documentDownloaded

    try {
      documentDownloaded = await app.$api.downloadExternalDocument(
        externalDepartmentId,
        externalDocumentReference
      )
    } catch (error) {
      handleError(error, externalDocumentReference)
    }

    if (!documentDownloaded) {
      handleError()
    }

    // blob link to download
    const mimeType = 'application/pdf'
    var blob = new Blob([documentDownloaded.data], { type: mimeType })
    var URL = window.URL || window.webkitURL
    const url = window.URL.createObjectURL(blob)
    const createTag = document.createElement.bind(document)
    const link = createTag('a')
    link.target = '_blank'
    link.href = url

    link.setAttribute('download', `${title}.pdf`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
    URL.revokeObjectURL(url)
  }

  inject('externalDocument', download)
}
