export default async function ({
  from,
  to,
  redirect,
  app,
  route,
  store,
  params,
}) {
  // Disable index page until productFrontpage is available
  if (route.name === 'index') {
    redirect('/login')
  }

  // Update store to show current path
  const currentPath = route.matched?.[0]?.path

  // A path is "/:department/parent/child".
  // We need to remove the first /, and split it to get a 3-item array at max
  const currentPathSplit = currentPath?.replace('/', '')?.split('/')

  // Get max index of array
  const pathMaxIndex = currentPathSplit?.length - 1

  // Depending on max index, we may or may not have a proper parent route
  const parentIndex = pathMaxIndex - 1 > 0 ? pathMaxIndex - 1 : null

  // If possible, try and identify a parent path, such as "/min-bolig". If no parent, this is undefined
  const parentPartialPath = currentPathSplit?.[parentIndex]

  // Re-assemble path. Returns either /:department or /:department/parent.
  const parentPath = parentPartialPath
    ? `/${currentPathSplit?.[0]}/${parentPartialPath}`
    : `/${currentPathSplit?.[0]}`

  // Find matching parent route in routes object. Used to display title etc.
  const parentRoute = app.router.options.routes.find(
    route => route.path === parentPath
  )

  store.commit('navigation/setCurrentParent', parentRoute)
}
