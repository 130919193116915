//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
export default {
  props: { click: Function, link: Object },
  computed: {
    ...mapGetters({
      department: 'department',
      owner: 'boardDocuments/owner',
    }),
    tenantIsBoardMember() {
      const currentTenant = this.$store.getters['user/currentTenant']
      const isPartOfBoard = currentTenant?.roller?.length > 0
      return isPartOfBoard
    },
    isLoggedIn() {
      return this.$store.getters['auth/getToken'] !== null
    },
    linkIsPublic() {
      return this.link?.meta?.public
    },
    lockLink() {
      if (!this.isLoggedIn && !this.linkIsPublic) return true
      // Check if link is to board page
      if (this.link?.name === 'department-board' && !this.tenantIsBoardMember)
        return true
      return false
    },
    notifications() {
      if (this.link?.name !== 'department-inbox') return null

      const notifications = this.$store.getters['inbox/unreadMessages']
      if (!notifications) return
      return notifications?.count
    },
    pathWithoutDepartment() {
      const path = this.link?.path?.replace('/:department/', '')
      return path
    },
    pathIsChildLink() {
      return this.pathWithoutDepartment?.split('/')?.length > 1
    },
    routeWithoutDepartment() {
      const department = this.$store.getters['department']
      const departmentSlug = department?.slug
      const route = this.$route?.path?.replace(`/${departmentSlug}/`, '')
      const routeSplit = route?.split('/')
      if (this.pathIsChildLink) return `${routeSplit?.[0]}/${routeSplit?.[1]}`
      return routeSplit?.[0]
    },
    isActive() {
      return this.routeWithoutDepartment == this.pathWithoutDepartment
    },
  },
  methods: {
    ...mapMutations({
      setActiveChildFilterParams: 'setActiveChildFilterParams',
      setFetchingChildFilterParams: 'setFetchingChildFilterParams',
    }),
    async handleClick() {
      if (this.lockLink) {
        this.$store.commit('modal/setModal', {
          type: 'NotLoggedInModal',
          data: {
            icon: 'log-in',
            title: 'Log ind',
          },
          disableOverlayClick: true,
        })
        return
      }

      // If we're on mobile and click a link that should show child filters, like Documents, these need to be fetched after clicking.
      if (
        window.innerWidth < 1024 &&
        this.link.name === 'department-documents'
      )
        await this.fetchBoardCategories()

      this.click()
    },
    async fetchBoardCategories() {
      this.setFetchingChildFilterParams(true)
      try {
        const categories = await this.$api.boardCategories(
          this.owner.id,
          this.owner.type
        )
        const mappedCategories = categories?.data?.map(category => {
          return {
            id: category.id,
            title: category.name,
            filterValue: category.id,
            name: 'department-documents-board-documents',
            parentName: 'Bestyrelsesdokumenter',
          }
        })
        // Commit categories to store
        this.setActiveChildFilterParams(mappedCategories)
      } catch (error) {
        console.log("Couldn't fetch childFilterParams")
        console.error(error)
      }

      this.setFetchingChildFilterParams(false)
    },
  },
}
