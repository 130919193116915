import { debounce } from 'debounce'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/tailwind.config.js'

const { theme } = resolveConfig(tailwindConfig)

export default ({ store }) => {
  const MediaQueries = Object.entries(theme.screens).reduce(
    (acc, [key, value]) => {
      acc[key] = window.matchMedia(`(min-width: ${value})`)
      return acc
    },
    {}
  )
  const checkMediaQueries = () =>
    Object.entries(MediaQueries).forEach(([key, { matches }]) => {
      store.dispatch('screen/setSize', { key, value: matches })
    })
  checkMediaQueries()
  window.addEventListener(
    'resize',
    debounce(function () {
      checkMediaQueries()
    }, 100)
  )
}
