//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    beforeEnter: function (el) {
      const formElements = [].slice.call(
        el.querySelectorAll('[data-animation]')
      )
      this.$gsap.set(formElements, {
        opacity: 0,
        y: 25,
      })
    },
    enter: function (el, done) {
      const formElements = [].slice.call(
        el.querySelectorAll('[data-animation]')
      )
      this.$gsap.to(formElements, {
        opacity: 1,
        duration: 0.9,
        stagger: 0.075,
        ease: 'power3.out',
        y: 0,
        onComplete: done,
      })
    },
    leave: function (el, done) {
      const formElements = [].slice.call(
        el.querySelectorAll('[data-animation]')
      )
      this.$gsap.to(formElements, {
        opacity: 0,
        duration: 0.4,
        stagger: 0.025,
        ease: 'power2.inOut',
        onComplete: done,
      })
    },
  },
}
