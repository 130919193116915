//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isLoading: false,
      query: '',
      tenantDocuments: [],
      departmentDocuments: [],

      tenantDocumentResults: [],
      departmentDocumentResults: [],
      documentResults: [],
      handbookResults: [],
      pageResults: [],
      sustainableActionsResults: [],
    }
  },
  computed: {
    department() {
      return this.$store.getters['department']
    },
    queryNotEmpty() {
      return this.query?.length > 0
    },
    boligfyVersion() {
      return this.$store.getters['boligfyVersion']
    },
  },
  methods: {
    closeSearch() {
      this.query = ''
      this.clearResults()
    },
    clearResults() {
      this.documentResults = []
      this.departmentDocumentResults = []
      this.tenantDocumentResults = []
      this.handbookResults = []
      this.pageResults = []
      this.sustainableActionsResults = []
    },
    async getResults(query) {
      if (query === '') {
        this.clearResults()
        return
      }

      this.isLoading = true

      const queryLowerCase = query.toLowerCase()
      const departmentId = this.department?.id
      const results = await this.$api.search({
        query: queryLowerCase,
        department_id: departmentId,
      })

      this.isLoading = false

      // Split documents
      if (this.boligfyVersion !== 'tenant-only') {
        const documents = results?.documents
        this.documentResults = documents
      }

      // Split articles
      const articles = results?.articles
      this.handbookResults = articles

      // Split pages
      const pages = results?.pages
      this.pageResults = pages

      // Split sustainable actions
      const sustainableActions = results?.sustainableActions
      this.sustainableActionsResults = sustainableActions

      // Search in department documents
      const departmentDocumentsSearched = this.departmentDocuments?.filter(
        document => {
          let title = document?.noteTypeTekst
          if (document.bemærkning && title !== document.bemærkning)
            title = `${title} (${document.bemærkning})`
          return title.toLowerCase().includes(queryLowerCase)
        }
      )
      const departmentDocumentsFiltered = departmentDocumentsSearched?.slice(
        0,
        3
      )
      this.departmentDocumentResults = departmentDocumentsFiltered

      if (this.boligfyVersion !== 'board-only') {
        // Search in tenant documents
        const tenantDocumentsSearched = this.tenantDocuments?.filter(
          document => {
            let title = document?.noteTypeTekst
            if (document.bemærkning && title !== document.bemærkning)
              title = `${title} (${document.bemærkning})`
            return title.toLowerCase().includes(queryLowerCase)
          }
        )
        const tenantDocumentsFiltered = tenantDocumentsSearched?.slice(0, 3)
        this.tenantDocumentResults = tenantDocumentsFiltered
      }
      return
    },
    async searchFocused() {
      // Fetch deparment documents
      if (!this.departmentDocuments?.length) {
        const extId = this.department?.ext_department_id
        const departmentDocuments =
          await this.$api.departmentDocumentsByExtId(extId)
        this.departmentDocuments = departmentDocuments?.data?.dokumenter
      }

      // Fetch tenant documents
      if (!this.boligfyVersion !== 'board-only') {
        if (!this.tenantDocuments.length) {
          const tenantDocuments = await this.$api.tenantExternalDocuments()
          this.tenantDocuments = tenantDocuments?.data?.dokumenter
        }
      }
    },
  },
  watch: {
    query(newQuery, oldQuery) {
      this.getResults(newQuery)
    },
    $route(to, from) {
      this.query = ''
    },
  },
}
