//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imageClass: { type: String },
    width: { type: String },
    height: { type: String },
    loading: { type: String, default: () => 'lazy' },
    src: { type: String, required: true },
    alt: { type: String, default: () => '' },
  },
  data() {
    return {
      imageLoaded: false,
      error: false,
    }
  },
  methods: {
    handleImageError() {
      this.error = true
      this.imageLoaded = true
    },
    onImageLoad() {
      this.imageLoaded = true
    },
  },
}
