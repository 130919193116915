//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import da from '../static/locales/da.json'
import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      modalType: 'modal/type',
      drawerType: 'drawer/type',
      toastType: 'toast/type',
      userInfo: 'user/userInfo',
      showTechnicalIssuesOverlay: 'showTechnicalIssuesOverlay',
    }),
    routeName() {
      let routeName = null
      switch (this.$route?.path) {
        case '/login':
          routeName = 'Login'
          break
        case '/resetPassword':
          routeName = 'Nulstil password'
          break
        default:
          routeName = null
          break
      }
      return routeName
    },
    pageTitle() {
      let pageTitle = 'Boligfy'
      if (this.departmentName) pageTitle = `${this.departmentName} | Boligfy`
      if (this.routeName) pageTitle = `${this.routeName} | Boligfy`
      if (this.departmentName && this.routeName)
        pageTitle = `${this.routeName} | ${this.departmentName} | Boligfy`
      return pageTitle
    },
    pageDescription() {
      return this.$t('meta.page-descriptions.generic')
    },
  },
  methods: {
    ...mapMutations({
      setModal: 'modal/setModal',
      closeModal: 'modal/closeModal',
    }),
  },
  created() {
    this.closeModal()
    // TODO implement proper translations from backend
    const translations = da
    this.$store.commit('translations/setTranslations', translations)
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageDescription,
        },
      ],
    }
  },
  mounted() {
    if (this.showTechnicalIssuesOverlay) {
      this.setModal({
        type: 'TechnicalIssuesModal',
        data: {
          icon: 'complaints',
          title: 'Tekniske problemer',
        },
        disableOverlayClick: true,
      })
    }
    const translateContainer = document.querySelector(
      '.google_translate_container'
    )
    translateContainer.classList.remove('google_translate_container--default')
    translateContainer.classList.add('google_translate_container--landing')
  },
}
