export const state = () => ({
  token: null,
})

export const mutations = {
  setToken(state, token) {
    state.token = token
  },
  setTokenCookie(state, token) {
    //state.token = token;
    this.$cookies.set('boligfy-auth', token, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7 * 4.33 * 3, // 3 months
    })
  },
}

export const actions = {
  clearCookies() {
    this.$cookies.remove('mitID-auth')
    this.$cookies.remove('boligfy-auth')
  },
}

export const getters = {
  getToken: state => state?.token,
}
